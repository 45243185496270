.page--team {
    .pivot-circle {
        position: absolute;
    }
}

[modal-open="manager_1"] {
  &::before {
    content: 'The buck stops at him';
    font-family: "Montserrat";
    display: block;
    top: 65%;
    position: absolute;
    z-index: 1;
    background: $primary_green;
    padding: 8px 10px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-size: 10px;
    font-weight: $bold;
    left: 0;
  }
}

.management--section {
    margin-bottom: 80px;
    .grid {
        &--container {
            @extend %df;
            @extend %aifs;
            margin-right: -10px;
            .grid {
                margin-right: 10px;
                width: calc(100% / 4);
                opacity: 0;
                transform: translate(0, 70px);
                transition: all 1.5s cubic-bezier(0.215, 0.61, 0.355, 1);
                &:nth-child(2) {
                    transition-delay: 150ms;
                }
                &:nth-child(3) {
                    transition-delay: 300ms;
                }
                &:nth-child(4) {
                    transition-delay: 450ms;
                }
                a {
                    display: block;
                    img {
                        vertical-align: middle;
                        width: 100%;
                    }
                }
            }
            &.show {
                .grid {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }
        span {
            display: block;
            line-height: 1.5;
        }
        img {
            filter: grayscale(10);
            transition: all 0.8s ease-in-out;
            &:hover {
                filter: none;
                // -webkit-filter: grayscale(0);
                // -webkit-transform: scale(1.01);
            }
        }
        &--name {
            padding: 10px 0;
            &-designation,
            &-title {
                color: $primary_brown;
            }
            &-title {
                font-weight: $bold;
                font-size: $medium_font;
            }
            &-designation {
                font-size: $small_font;
                padding: 10px 0;
            }
        }
    }
}

.team-node {
    &.green {
        background-color: $primary_green;
        .image-container {
            &::after {
                opacity: 1;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                top: 50%;
                &::before {
                    display: none;
                }
            }
            img {
                width: 100%;
                filter: none;
                &.bg-image {
                    display: block !important;
                }
            }
            .link {
                position: absolute;
                bottom: 20px;
                left: 10px;
                display: inline-flex;
                align-items: center;
                width: calc(100% - 20px);
                font-size: 11px;
                font-weight: $normal;
                opacity: 0;
                transition: all 500ms ease-in-out;
                z-index: 10;
            }
        }
    }
}

.team {
    &-node {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background: #ccc;
        overflow: hidden;
        cursor: none;
        transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        will-change: transform, opacity;
        opacity: 0.7;
        transform: translate(0, 20px);
        transform-origin: center;
        color: $black;
        &.show {
            opacity: 1;
            transform: translate(0, 0);
            &.small {
                transform: translate(0, -20px);
                opacity: 0.7;
            }
        }
        &.open {
            //transform: scale(1.3);
            border-radius: 20px;
            height: 250px;
            width: 250px;
            z-index: 1;
            cursor: pointer;
            .image-container {
                padding-bottom: 25px;
                border-radius: 0;
                background-color: $primary_green;
                &::before,
                &::after {
                    opacity: 1;
                }
                img {
                    filter: none;
                    border-radius: 0;
                    opacity: 0;
                    &.bg-image {
                        opacity: 0;
                    }
                }
                .link {
                    opacity: 1;
                }
            }
        }
        .image-container {
            overflow: hidden;
            width: 100%;
            height: 100%;
            padding-bottom: 0;
            transition: all 500ms ease-in-out 50ms;
            position: relative;
            border-radius: 50%;
            -ms-overflow-style: none;
            /* IE 11 */
            scrollbar-width: none;
            /* Firefox 64 */
            transform-origin: center;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.5;
            &::-webkit-scrollbar {
                width: 0px;
            }
            &.small-font {
                &::after {
                    font-size: 10px;
                }
            }
            &:before,
            &:after {
                font: $normal 11px $fontFamily;
                position: absolute;
                left: 0;
                padding: 10px;
                opacity: 0;
                transition: all 500ms ease-in-out 50ms;
                z-index: 4;
                text-transform: capitalize;
            }
            &::after {
                content: attr(data-quote);
                top: calc(50% - 12px);
                transform: translateY(-50%);
            }
            &::before {
                content: attr(data-name);
                top: calc(70% - 12px);
                transform: translateY(50%);
            }
            img {
                transition: all 500ms ease-in-out;
                filter: grayscale(100%);
                will-change: filter;
                border-radius: 0;
                z-index: 2;
                position: relative;
                &.bg-image {
                    z-index: 1;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: none;
                }
            }
        }
        &.move-mXmY {
            transform: translate(-50px, -50px);
        }
        &.move-pXmY {
            transform: translate(50px, -50px);
        }
        &.move-pXpY {
            transform: translate(50px, 50px);
        }
        &.move-mXpY {
            transform: translate(-50px, 50px);
        }
    }
    &--all {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        will-change: transform;
    }
    &--container {
        position: relative;
        background: #fff;
        pointer-events: all!important;
        h2 {
            font-weight: $bold;
            font-size: $xl6_font;
            color: $black;
            text-align: center;
        }
    }
}

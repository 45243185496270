.works {
    &--pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #d5d5d5;

        &-title {
            background-color: $white;
            padding: 30px;

            p {
                font: $bold $large_font $fontFamily;
                color: $primary_brown;
            }
        }

        &-next {
            background-color: #d5d5d5;
            display: flex;
            align-items: center;

            a {
                display: flex;
                padding: 30px;
                font: $extraBold $medium_font $fontFamily;
                color: $primary_brown;
                align-items: center;

                i {
                    font-size: 24px;
                    padding-left: 20px;
                }
            }
        }
    }

    &--detail {
        height: 100%;

        &-wrapper {
            width: 50%;
            // align-self: flex-end;
            margin-right: 5%;

            @media only screen and (max-width: 1366px) {
                align-self: center;
            }
        }

        &--footer {
            position: fixed;
            left: 0;
            bottom: 0;
            transform: translate3d(0, 100%, 0);
            transition: all .3s ease;
            z-index: 889;

            &.show {
                transform: translate3d(0, 0, 0);
            }
        }

        &-features {
            margin-bottom: 50px;

            h3 {
                font: $blackBold $xxl_font $fontFamily;
                color: $primary_brown;
                margin-bottom: 10px;
            }

            ul {
                li {
                    font: $medium $medium_font $fontFamily;
                    color: $primary_brown;
                }
            }
        }

        &-multilist {
            >li {
                @extend %df;
                @extend %aic;
                @extend %jcc;
                margin-bottom: 25px;
                padding-top: 25px;
                border-top: 1px solid $lightGrey;

                p {
                    line-height: 1.4;
                }
            }

            &-wrap {
                flex: 1;
            }
            
            &-list {
                li {
                    list-style: disc outside;
                    line-height: 1.4;
                }
            }
        }

        &-wellbeing {
            @extend %df;
            @extend %fw;
            @extend %jcsb;
            
            li {
                list-style: none !important;
                width: calc((100% / 2) - 50px);
                margin-bottom: 30px !important;
            }
        }

        &-paysense {
            >li {
                padding: 30px;
                margin-bottom: 20px !important;
                border: 1px solid $lightGrey;
                @extend %df;
                @extend %aic;
                @extend %jcsb;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            
            &-group {
                width: 70%;
                
                ul {
                    @extend %df;
                    @extend %jcsb;

                    li {
                        list-style: none !important;
                    }
                }
            }
        }

        &-biluma {
            font-size: $large_font;
            font-weight: $bold;
            line-height: 1.4;

            &-wrap {
                display: block;

                ul {
                    display: flex;
                    margin-bottom: 40px;

                    li {
                        width: calc(100% / 3);

                        h4 {
                            font: $bold $xxl_font $fontFamily;
                            width: fit-content;
                            margin: 0 auto;
                        }

                        p {
                            font: $bold $small_font $fontFamily;
                            margin-right: 20px;
                            line-height: 1.5;
                        }

                        &:last-child {
                            p {
                                margin-right: 0px;
                            }
                        }
                    }

                    &:first-child {
                        padding: 40px 0px;
                        background: url(../../../images/case-studies/biluma-wrapper.webp) no-repeat;
                        background-size: 100%;

                        @media only screen and (max-width: 1660px) {
                            padding: 30px 0px;
                            margin-bottom: 20px;
                        }

                        @media only screen and (max-width: 768px) {
                            padding: 0px;
                        }
                    }
                }
            }

            &-milestone {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                li {
                    list-style: none !important;
                    margin-right: 20px;

                    width: calc(50% - 20px);
                }
            }
        }

        &-result {
            display: flex;

            &-container {
                width: 70%;
            }

            &-wrap {
                border: 1px solid $primary_brown;
                border-radius: 20px;
                padding: 15px 20px;
                font: $normal $small_font $fontFamily;
                display: block;
            }

            &-text {
                font: $blackBold $xl5_font $fontFamily;

                &.block {
                    display: block;
                }

                &.blue {
                    color: rgba($blue, 0.8);
                }
            }
        }

        &-fnp {
            border-bottom: 1px solid rgba($primary_brown, 0.1);
            padding-bottom: 10px;

            li {
                border-top: 1px solid rgba($primary_brown, 0.1);
                padding-top: 10px;
                margin-bottom: 10px;
            }

            h3 {
                font: $blackBold $xxl_font $fontFamily;
                color: $primary_brown;
            }

            p {
                font: $normal $small_font $fontFamily;
                color: $primary_brown;
                line-height: 1.4;
            }

            &-wrap {
                width: 40%;
            }

            &-results {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    list-style-type: none !important;
                    width: calc((100% / 2) - 50px);
                    margin-right: 50px;
                    text-align: center;

                    &:nth-child(2n),
                    &:last-child {
                        margin-right: 0;
                    }

                    .title,
                    .info {
                        color: $primary_brown;
                        display: block;
                    }

                    .title {
                        font: $normal $small_font $fontFamily;
                    }

                    .info {
                        font: $blackBold $xxl_font $fontFamily;
                    }
                }
            }
        }

        &-kaya {
            display: flex;
            justify-content: space-between;

            &-wrap {
                width: calc((100% / 3) - 20px);
                background-color: $lightGrey;
                border-radius: 30px;
            }

            &-head {
                padding: 25px 20px;
                display: flex;
                position: relative;
                z-index: 1;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    inset: 0;
                    z-index: -1;
                    background-color: $kaya;
                    clip-path: polygon(0 0, 0 50%, 0 100%, 25% 100%, 35% 65%, 100% 65%, 100% 0, 0 0);
                    border-radius: 30px 30px 0 0;
                }

                .heading {
                    font: $bold $large_font $fontFamily;
                    color: $white;
                }
            }

            &-list {
                padding: 25px 40px;
                font: $normal $small_font $fontFamily;
            }
        }

        &-lti {
            margin-bottom: 70px;

            li {
                margin-bottom: 50px;
            }

            h3 {
                font: $blackBold $xxl_font $fontFamily;
                color: $primary_brown;
            }

            p {
                font: $normal $medium_font $fontFamily;
                color: $primary_brown;
                line-height: 1.4;
            }
        }

        &-lti,
        &-fnp {

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;


                &:nth-child(2n) {
                    flex-direction: row-reverse;
                }
            }

           
        }
        &-first-customer{
            display: flex;
            img{
                width: 30%;
            }
            p{
                font-size: $large_font;
                font-family:  $fontFamily;
            }
        }
        &-hpv{
            margin-bottom: 50px;
           li{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            
           }
           
           h3 {
            font: $blackBold $xxl_font $fontFamily;
            color: $primary_brown;
        }
            &-wrap{
                p{
                    font: $normal $large_font $fontFamily;
                    color: $primary_brown;
                    line-height: 1.5;
                   
                   }
            }
        }
    }
}

.works--detail-back {
    position: fixed;
    right: 130px;
    top: 0;
    height: 104px;
    width: 104px;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    z-index: 2;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0) !important;
        transform: translate3d(-50%, -50%, 0) !important;
    }

    &:active {
        svg {
            -webkit-transform: translate(-50%, -50%) scale(.9) !important;
            transform: translate(-50%, -50%) scale(.9) !important;
        }
    }

    path {
        -webkit-transition: stroke .3s ease, fill .3s ease;
        transition: stroke .3s ease, fill .3s ease;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
        border-radius: 50%;
        border: 2px dashed #fff;
        pointer-events: none;
        width: 80%;
        height: 80%;
        opacity: 0;
    }
}

.works--detail-mainwrapper {
    display: block;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% / 2 + 110px);
    height: 100%;
    will-change: transform;
    z-index: 888;
    padding: 100px 0 0 70px;
    transform: translateX(120%);
    transition: transform .6s ease;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;

    /* Firefox 64 */
    &::-webkit-scrollbar {
        width: 0px;
    }

    // &:before {
    // 	content: "";
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // 	box-shadow: -19px 0 30px 0 rgba(0, 0, 0, .2);
    // 	pointer-events: none;
    // 	transition: opacity .3s ease;
    // }
    .works--detail-main {
        transition: opacity .3s ease .6s;
    }
}

.works--detail-mainwrapper.fade-enter {
    transform: translateX(0);
    box-shadow: 0 0 63px rgba(0, 0, 0, 0.34);
}

.works--detail-mainwrapper.fade-enter .works--detail-main,
.works--detail-mainwrapper.fade-enter .works--detail-mainBar,
.works--detail-mainwrapper.fade-enter:before {
    opacity: 1;
}

.works--detail-main {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    // overflow-y: scroll;
    &:after {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f0f0f0;
        z-index: 2;
        width: 3px;
    }
}

.works--detail-mainbar {
    position: absolute;
    right: 100%;
    height: 100%;
    width: calc((100vw - 107px)/9*3);
}

.works--detail-mainClose {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;

    svg {
        position: absolute;
        top: 21px;
        right: 24px;
        border: 2px dashed transparent;
        border-radius: 50%;
    }
}

.works--detail {
    &-clientlogo {
        margin-bottom: 20px;
        opacity: 0;
        transform: translateY(70px);

    }

    &-maincontent,
    &-maincontent-wrap {
        h3 {
            font: $blackBold $xxl_font $fontFamily;
            color: $primary_brown;
        }

        h4 {
            font: $semiBold $xxl_font $fontFamily;
        }
    }

    &-maincontent {
        margin-bottom: 70px;

        &.small {
            max-width: 80%;
            width: 100%;
            margin: 0 auto;
        }

        ul {
            li {
                font: $normal $large_font $fontFamily;
                color: $primary_brown;
                list-style: disc;
                line-height: 1.5;
                margin-bottom: 10px;
            }

            &.list {
                margin: 50px 0;

                li {
                    list-style: decimal;
                }
            }
        }

        p {
            font: $normal $medium_font $fontFamily;
            color: $primary_brown;
            line-height: 1.5;
            margin: 20px 0;

            &.big {
                font-size: $xl5_font;
            }

            &.second-para {
                font: $bold $xxl_font $fontFamily;
            }

            span {
                font-weight: $bold;
            }
        }

        img.shifted {
            transform: translateX(50px);
            display: block;
        }

        &.outlined {
            border: 1px solid $primary_green;
            border-radius: 10px;
            box-shadow: $boxShadow;
            padding: 50px 0;

            .inner-content {
                padding: 0 50px 20px;
            }
        }

        .approach {
            &--detail {
                display: flex;
                position: relative;
                justify-content: center;
                margin-bottom: 20px;

                &-circle {
                    width: 20vw;
                    height: 20vw;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    padding: 0 70px;
                    position: relative;

                    h3 {
                        font: $blackBold $xl5_font $fontFamily;
                        color: $black;
                    }

                    p {
                        font: $normal $base_font $fontFamily;
                        color: $black;
                        line-height: 1.2;
                    }

                    &.approach--arrow {
                        position: relative;

                        &:after {
                            content: '\e903';
                            font-size: 44px;
                            position: absolute;
                            right: 0;
                            z-index: 12;
                        }
                    }

                    &.bg--green {
                        margin-left: -30px;
                        z-index: 1;
                    }
                }
            }
        }

        &-wrap {
            ul {
                li {
                    font: $normal $base_font $fontFamily;
                }
            }
        }
    }

    &-approach {
        margin-bottom: 80px;
    }

    &-preview {
        display: block;
        width: 100%;
        height: 100vh;
        position: relative;
    }

    &-previewloader {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        @extend %aic;
        @extend %jcsb;
        background-color: hsla(0, 0%, 100%, .5);

        // z-index: 1;
        &.fade-leave-active {
            transition: opacity .3s ease;
        }

        &.fade-enter-active {
            transition: opacity .3s ease 1s;
        }

        &.fade-enter,
        &.fade-leave-to {
            opacity: 0;
        }

        &-hide {
            opacity: 0;
            transition: opacity .3s ease;
        }
    }

    &-previewoverflow {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        // padding-right: 104px;
        will-change: clip-path;
        background-color: #FBB179;

        &:before {
            content: "";
            background-image: linear-gradient(225deg, #fff, transparent);
            pointer-events: none;
            opacity: .15;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &-previewinner {
        display: flex;
        width: 100%;
        height: 100%;
        @extend %aic;
        justify-content: flex-start;
        position: relative;

        .back-link-arrow {
            position: absolute;
            left: 21px;
            top: 100px;
            @extend %df;
            @extend %aic;
            height: 20px;
            width: 40px;
            z-index: 1;

            &.white {
                span.line {
                    background-color: $white;
                }
            }

            span {
                position: absolute;
                top: calc(50% - 1px);
                left: 0;
                transition: all .3s cubic-bezier(0.68, -0.6, 0.32, 1.6);

                &.line {
                    height: 2px;
                    background-color: $black;

                    &.main {
                        width: 100%;
                    }

                    &.hand {
                        width: 15px;
                        transform-origin: 0% 50%;

                        &.top {
                            transform: rotate(-35deg)
                        }

                        &.bottom {
                            transform: rotate(35deg)
                        }
                    }
                }
            }

            &:hover {
                span {
                    &.line {
                        &.main {
                            width: calc(100% + 10px);
                        }

                        &.hand {
                            width: 20px;

                            &.top {
                                transform: rotate(-45deg)
                            }

                            &.bottom {
                                transform: rotate(45deg)
                            }
                        }
                    }
                }
            }
        }
    }

    &-previewimage {
        // position: absolute;
        // left: 0;
        // top: 0;
        // width: 100%;
        // height: 100%;
        // min-height: 170px;
        // overflow: hidden;
        opacity: 0 !important;
        transform: translateZ(0);
        transition: opacity .3s ease;
        will-change: transform;
        z-index: 1;
        display: block;

        img {
            // width: 100%;
            // height: 100%;
            // object-fit: contain;
            // object-position: center;
        }

        &-show {
            opacity: 1 !important;
        }
    }

    &-previewimageloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-previewcontent {
        color: $black;
        padding-right: 50px;

        &-half {
            width: 50%;
        }
    }

    &-previewlists {
        display: flex;
        margin-bottom: 64px;

        .tagList {
            padding-left: 120px;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    &-taglist {
        margin-right: 10%;
        opacity: 0;
        transform: translateY(100px);

        &-title {
            font: $blackBold $base_font $fontFamily;
            margin-bottom: 20px;
        }
    }

    &-previewtitle {
        font: $blackBold $xl5_font $fontFamily;
        // color: $black;
        letter-spacing: -.016em;
        margin-bottom: 31px;
        z-index: 3;
        position: relative;
        opacity: 0;
        transform: translateY(70px);
    }

    &-previewdescription {
        padding-left: 20%;
        font: $medium $xl_font $fontFamily;
        color: $black;
        letter-spacing: -.005em;
        margin-bottom: 80px;
        position: relative;
    }

    &-previewbtn {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateY(100px);

        .button-more-text {
            position: relative;
            font: $bold $xs_font $fontFamily;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
                border-radius: 19px;
                border: 2px dashed #fff;
                pointer-events: none;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                opacity: 0;
            }
        }
    }
}

.color---white {
    color: white !important;
}

.full--width {
    margin-left: -70px;
    margin-right: -50px;
    width: calc(100vw / 2 + 110px);
    max-width: unset;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
    ::before,
    :after {
        font-family: $icomoon-font-family;
    }
}



html {
    &::-webkit-scrollbar {
        width: 0;
    }
    background-color: $white;
}

body {
    background-color: $white;
    -webkit-overflow-scrolling: touch;
    font-weight: $normal;
    font-size: $base_font;
    font-family: $fontFamily;
    text-size-adjust: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    //	cursor: none;
    @media only screen and (max-width: 1920px) {
        font-size: $base_font;
    }
    @media only screen and (max-width: 1440px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 1366px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 9px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
    // @media only screen and (max-width: 375px) {
    //     font-size: 8px;
    // }
    &.block {
        overflow: hidden;
    }
    &.cursor-none {
        cursor: none;
    }
}

:not(body) {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

#app,
body,
html {
    margin: 0;
    touch-action: manipulation;
    width: 100%;
    // overflow-x: hidden;
    -ms-scroll-chaining: none;
    // overscroll-behavior: none;
    font-family: $fontFamily;
    font-weight: $normal;
    user-select: none;
}

#app {
    @media only screen and (max-width: 768px) {
        padding-left: 5px;
    }
}

p {
    line-height: normal;
}

button,
input:not([type='checkbox']),
select {
    border: none;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: inline-block;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    border-radius: 0;
}

:focus {
    outline: none;
}

a {
    text-decoration: none;
    font-size: $base_font;
    &.black {
        color: $black;
    }
}

button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: $medium_font;
}

img {
    max-width: 100%;
    height: auto;
}

::placeholder {
    color: $secondary_grey;
    font-size: $small_font;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $secondary_grey;
    font-size: $small_font;
}

::-ms-input-placeholder {
    color: $secondary_grey;
    font-size: $small_font;
}

.viewport {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .scroll-container {
        position: absolute;
        overflow: hidden;
        z-index: 10;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        width: 100%;
        will-change: transform, scroll-position;
        min-height: 100%;
    }
}

.center--theme-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1024px) {
        width: 90%;
    }
    &--fluid {
        width: 100%;
        padding-left: 6%;
        padding-right: 6%;
    
        @media screen and (max-width: 1024px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
}

.bg {
    &--lightgrey {
        background-color: $lightGrey;
    }
    &--green {
        background-color: $primary_green;

        &-secondary {
            background-color: $secondary_green;
        }
    }
    &--blue {
        background-color: $blue;
    }
    &--green{
        background-color: $green;
    }
    &--white{
        background-color: $white;
    }
}

.btn {
    height: 46px;
    line-height: 46px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    transition: all 0.3s ease-out;
    font-size: $medium_font;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: $normal;
    padding: 0 20px;
    border-radius: 25px;
    position: relative;
    border: 0;
    text-decoration: none!important;
    &.brown {
        background-color: $primary_brown;
        color: $white;
        font-size: 16px;
    }
    &.green {
        background-color: $primary_green;
    }
    &.big {
        padding: 0 60px;
    }
    &.square {
        border-radius: 0;
    }
}

.link {
    text-decoration: underline;
    display: inline-block;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    &.show {
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.common--cell {
    position: relative;
    @extend %df;
    @extend %jcc;
    @extend %aic;
    width: 100%;
    .common--cell-main {
        @extend %df;
        @extend %jcc;
        @extend %aic;
    }
    .banner {
        &--image {
            z-index: 1;
            opacity: 0;
            &-about {
                @media only screen and (max-width: 1440px) {
                    width: 40%;
                }
            }
            &-service {
                @media only screen and (max-width: 1440px) {
                    width: 50%;
                }
            }
            &-team {
                @media only screen and (max-width: 1440px) {
                    width: 60%;
                    transform: translate(-10%, 5%);
                }
            }
            &-work {
                @media only screen and (max-width: 1440px) {
                    width: 60%;
                }
                @media only screen and (max-width: 640px) {
                    width: 70%;
                }
            }
            &-contact {
                @media only screen and (max-width: 1440px) {
                    width: 40%;
                }
            }
            &-career {
                transform: translate(-11%, -4%);
                height: auto;
                @media only screen and (max-width: 1440px) {
                    transform: translate(-10%, 0%);
                }
                @media only screen and (max-width: 1366px) {
                    transform: translate(-13%, -5%);
                }
            }
            @media only screen and (max-width: 640px) {
                width: 63%;
                margin-top: 100px;
            }
        }
        // margin-right: -15%;
        &--text {
            transform: translate(0, 0);
            opacity: 1;
            h2 {
                font-weight: $light;
                font-size: $xl5_font;
                color: $black;
                margin-top: 10px;
                transform: translateX(100px);
                opacity: 0;
                padding-left: 30px;
                @media only screen and (max-width: 640px) {
                    font-size: 3em;
                }
            }
            @media only screen and (max-width: 640px) {
                font-size: 5px;
                margin-left: -34px;
            }
        }
    }
}

.z--index {
    z-index: 2;
    position: relative;
}

.pivot {
    &--box {
        position: relative;
        display: inline-block;
        background-color: $white;
        border-radius: 1000px;
        padding: 20px 30px;
        opacity: 0;
        transform: translateX(100px);
        &-title {
            font-weight: $blackBold;
            font-size: $xl6_font;
            color: $black;
            span {
                font-weight: $light;
            }
        }
    }
}

.section--title {
    font-size: $xl5_font;
    text-align: center;
    span {
        font-weight: $blackBold;
    }
}

.big--title {
    font-size: $xl7_font;
    &.light {
        font-weight: $extraLight;
    }
    &.bold {
        font-weight: $bold;
    }
}

.pivot-circle,
.pivot-circle-inner {
    background-color: $primary_green;
    border-radius: 50%;
    @extend %df;
    @extend %jcfe;
    @extend %aic;
    opacity: 0;
    &.grey {
        background-color: #e3e3e3;
    }
}

.pivot-circle {
    position: absolute;
    &-inner {
        box-shadow: 0 0 0 20px $primary_green;
        &.grey {
            box-shadow: 0 0 0 20px #e3e3e3;
        }
    }
}

// .banner {
//     &--image {
//         z-index: 1;
//         opacity: 0;
//         // @media only screen and (max-width: 1440px) {
//         // 	height: 600px;
//         // }
//         // @media only screen and (max-width: 1366px) {
//         // 	height: 510px;
//         // }
//     }
// }
@keyframes zoomOut {
    from {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    to {
        width: 55vh;
        height: 55vh;
        border-radius: 50%;
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

.container--divided {
    @extend %df;
    &.v-center {
        align-items: center;
        @media only screen and (max-width: 640px) {
            flex-direction: column;
        }
    }
    &>div {
        width: 50%;
        &:nth-child(1) {
            margin-right: 2%;
        }
        &:nth-child(2) {
            margin-left: 2%;
        }
        @media only screen and (max-width: 640px) {
            width: 100%;
            &:nth-child(1) {
                margin-right: 0;
            }
            &:nth-child(2) {
                margin-left: 0;
            }
        }
    }
}

blockquote {
    color: $black;
    font-size: $base_font;
    line-height: 27px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    &::before {
        content: '';
        color: $primary_green;
        min-width: 70px;
        background-image: url("./images/quote.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 20px;
        display: block;
        transform: translateY(-30px);
    }
    p {
        margin-bottom: 20px;
    }
    span {
        font-weight: $bold;
    }
}

.color {
    &--black {
        color: $black;
    }
    &--white {
        color: $white;
    }
    &--red {
        color: $red;
    }
    &--grey {
        color: $fontGrey;
    }
    &--lightgrey {
        color: $lightGrey;
    }
    &--green {
        color: $primary_green;
    }
    &--rbl {
        background: #e5f6fc;
        background: -moz-linear-gradient(left, #e5f6fc 0%, #7a95aa 100%);
        background: -webkit-linear-gradient(left, #e5f6fc 0%, #7a95aa 100%);
        background: linear-gradient(to right, #e5f6fc 0%, #7a95aa 100%);
    }

    &--biluma {
        background-image: -moz-linear-gradient(to right, #e6ccb4, #e9d1bb, #ecd6c2, #efdbc9, #f2e0d0, #f4e4d6, #f6e7db, #f8ebe1, #f9eee6, #fbf1eb, #fcf5f0, #fdf8f5);
        background-image: -webkit-linear-gradient(to right, #e6ccb4, #e9d1bb, #ecd6c2, #efdbc9, #f2e0d0, #f4e4d6, #f6e7db, #f8ebe1, #f9eee6, #fbf1eb, #fcf5f0, #fdf8f5);
        background-image: linear-gradient(to right, #e6ccb4, #e9d1bb, #ecd6c2, #efdbc9, #f2e0d0, #f4e4d6, #f6e7db, #f8ebe1, #f9eee6, #fbf1eb, #fcf5f0, #fdf8f5);
    }

    &--brown {
        color: $primary_brown;
    }

    &--kaya {
        color: $kaya;
    }
    &--bisleri{
        background-image: linear-gradient(to right top, #94dbd3, #87d7d0, #78d4ce, #69d0cc, #57ccca);
    }
}

.text {
    &--upper {
        text-transform: uppercase;
    }
    &--semibold {
        font-weight: $semiBold;
    }
    &--bold {
        font-weight: $bold;
    }
    &--extrabold {
        font-weight: $extraBold;
    }
    &--normal {
        font-weight: $normal;
    }
}

.font {
    &--24 {
        font-size: $xxl_font;
    }

    &--14 {
        font-size: $small_font;
    }

    &--36 {
        font-size: $xl5_font;
    }
    &--18{
        font-size: $medium_font;
    }
}

.margin {
    &--0 {
        margin: 0;
    }
    // top
    &--t0 {
        margin-top: 0;
    }
    &--t10 {
        margin-top: 10px;
    }
    &--t15 {
        margin-top: 15px;
    }
    &--t20 {
        margin-top: 20px;
    }
    &--t25 {
        margin-top: 25px;
    }
    &--t30 {
        margin-top: 30px;
    }
    &--t40 {
        margin-top: 40px;
    }
    &--t50 {
        margin-top: 50px;
    }
    // bottom
    &--b5 {
        margin-bottom: 5px;
    }
    &--b10 {
        margin-bottom: 10px;
    }
    &--b15 {
        margin-bottom: 15px;
    }
    &--b20 {
        margin-bottom: 20px;
    }
    &--b25 {
        margin-bottom: 25px;
    }
    &--b30 {
        margin-bottom: 30px;
    }
    &--b40 {
        margin-bottom: 40px;
    }
    &--b50 {
        margin-bottom: 50px;
    }
    &--b70 {
        margin-bottom: 70px;
    }
    &--b90 {
        margin-bottom: 70px;
    }
    // right
    &--r5 {
        margin-right: 5px;
    }
    &--r10 {
        margin-right: 10px;
    }
    &--r20 {
        margin-right: 20px;
    }

    &--r35 {
        margin-right: 35px;
    }
    // left
    &--l10 {
        margin-left: 10px;
    }
    &--l15 {
        margin-left: 15px;
    }

    &--l35 {
        margin-left: 35px;
    }
}

.padding {
    &--section {
        padding: 50px 0 100px;
        @media only screen and (max-width: 768px) {
            padding: 50px 0;
        }
    }
}

.text {
    &--center {
        text-align: center;
    }
    &--left{
        text-align: left !important;
    }
}

#particle {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.48;
    pointer-events: none;
    &+div {
        position: relative;
    }
}

.rounded {
    &--right {
        display: flex;
        position: relative;
    }
    &--cirlce,
    &--circle {
        &-big {
            z-index: 1;
            position: absolute;
            &.right {
                right: 20%;
                @media only screen and (max-width: 1440px) {
                    right: 7%;
                }
            }
        }
        &-dotted {
            position: relative;
            @media only screen and (max-width: 500px) {
                padding: 30px 20px 0;
                margin-bottom: 50px;
            }
        }
        &-scroll-text {}
    }
}

.rounded--circle-scroll-text {
    position: absolute;
    right: 40%;
    bottom: 40px;
}

.bossman {
    &--section {
        &.panel {
            display: flex;
            align-items: center;
        }
    }
    &--quote {
        position: relative;
        z-index: 2;
        a {
            margin-left: 67px;
        }
    }
}

.display {
    &--alignself {
        &-center {
            align-self: center;
        }
        &-end {
            align-self: flex-end;
        }
    }
}

.block {
    display: block;

    &--center {
        margin: 0 auto;
    }
}
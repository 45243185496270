header {
    transition: opacity .5s cubic-bezier(.25, .46, .45, .94);
    @extend %df;
    padding-left: 20px;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    position: absolute;
    &.absolute {
        position: absolute;
    }
    .header--left {
        @extend %df;
        align-items: center;
        height: 50px;
    }
    .logo--wrapper {
        margin-left: 30px;
        .logo {
            transform: translateY(-80px);
            width: 200px;
        }
    }
    .hamburger-wrapper {
      cursor: pointer;
    }
    .hamburger {
        width: 40px;
        height: 40px;
        position: relative;
        transition: all 0.5s easeOut;
        background-color: transparent;
        span {
            display: block;
            position: absolute;
            height: 2px;
            background: #3C4042;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            opacity: 1;
            width: 0;
            border-radius: 25px;
            &:nth-child(1) {
                top: 0px;
            }
            &:nth-child(2) {
                top: 10px;
            }
            &:nth-child(3) {
                top: 21px;
            }
            &:nth-child(4) {
                top: 32px;
            }
        }
        &.open {
            span {
                &:nth-child(1) {
                    top: 18px;
                    transform: rotate(135deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                    left: -60px;
                }
                &:nth-child(3) {
                    top: 18px;
                    transform: rotate(-135deg);
                }
                &:nth-child(4) {
                    opacity: 0;
                    left: -100px;
                }
            }
        }
    }
    .hamburger-wrapper {
        &.sticky {
            position: fixed;
            top: 50%;
            left: 0;
            min-width: 210px;
            min-height: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            //	border-bottom-right-radius: 50%;
            box-shadow: 0 0 47px rgba(0, 0, 0, 0.2);
            background-color: $white;
            transform: translate(-100%, -50%);
            transition-delay: 300ms;
            transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
            backface-visibility: hidden;
            &.show {
                transform: translate(-105px, -50%) scale(0.5);
            }
            &.min {
                transform: translate(-105px, -50%) scale(0.5);
                // &:hover {
                // 	transform: translate(-95px, -50%) scale(1);
                //
                // }
            }
            .hamburger {
                margin-right: -90px;
            }
        }
    }
    .top--sliding-vertical {
        @extend %df;
        justify-content: flex-end;
        align-items: center;
        a {
            @extend %df;
            text-indent: 8px;
            width: 350px;
            justify-content: center;
            color: $white;
            position: absolute;
            opacity: 1;
            justify-content: flex-end;
            align-items: center;
            padding: 16px 0;
            color: $white;
            font-size: $medium_font;
            animation: fade 6s linear infinite;
            &:after {
                content: '';
                width: 30%;
                border: 1px solid #fff;
                margin-left: 20px;
            }
        }
        .firstanimation {
            background-color: $primary_brown;
        }
        .secondanimation {
            background-color: #3b5998;
            animation-delay: -4s;
        }
        .thirdanimation {
            animation-delay: -2s;
            background: #f09433;
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
            // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
            //background: linear-gradient(-135deg,#1400c8,#b900b4,#f50000)
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
        z-index: -1;
    }
    20% {
        opacity: 1;
        z-index: 2;
    }
    33% {
        opacity: 1;
        z-index: 2;
    }
    53% {
        opacity: 0;
        z-index: -1;
    }
    100% {
        opacity: 0;
    }
}

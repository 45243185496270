.page{
    &--blog{
        .pivot-circle {
            position: absolute;
        }
    }
}
.blog{
    &--update{
        &-news{

            .section--title{
                margin: 100px 0;

            }
        }
        &-list{
            @extend %df;
            @extend %jcsb;
            @extend %fw;
            max-width: 80%;
            margin: 0 auto;
        }
        &-inner{
            @extend %df;
            @extend %jcsb;
            @extend %aife;
            width: calc(100% / 2 - 25px);
            margin-bottom: 80px;
            position: relative;
            overflow: hidden;
            &:hover{
                .blog--update-img{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
        }
        &-img{
            width: 50%;
            overflow: hidden;
            img{
                width: 100%;
                transition: all 0.5s ease-in-out;
            }
        }
        &-info{
            padding: 30px;
            left: -100px;
            background-color: $white;
            width: 50%;
            position: relative;
            z-index: 2;
            .news{
                &-date{
                    margin-bottom: 23px;
                    display: block;
                }
                &-title{
                    font-size: $xl_font;
                    font-weight: bold;
                    padding-bottom: 60px;
                    line-height: 1.3;
                    border-bottom: 1px solid #B5B5B5;
                }
                &-link{
                    margin-top: 20px;
                }
            }
        }
    }

    &--detail{
        &-info{

                font-size: $base_font !important;
                color: $black !important;
                font-family: $fontFamily !important;
                display: block;
                margin-bottom: 20px;
                line-height: 1.5;
                i{
                    font-style: italic;
                }

            p, span {
                margin-bottom: 20px;
                line-height: 1.5;
            }

            img{
                width: 100% !important;
                display: block;
                margin-top: 20px;
            }
            .spotlight-list{
                list-style: disc;
                .list-items{
                    margin-bottom: 20px;
                }
                span{
                    font-weight: $bold;
                }
            }
        }
        p, span{
            font-size: $base_font !important;
            margin-bottom: 20px;
            line-height: 1.5;
            color: $black;

        }
        img{
            width: 100%;
        }
        &-link{
            text-decoration: underline;
        }
        &-authormain{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 70px;
        }
        &-authorinfo{
            margin-left: 25px;
        }
        &-authorlist{
            display: flex;
            align-items: center;
        }
        &-authorname{
            font-weight: $semiBold;
            font-size: $large_font;
            margin-bottom: 5px;
        }
        &-infolist{
            @extend %df;       
        }
        &-infodtl{
            color: #7A7A7A;
            font-size: $small_font;
            margin-right: 30px;
            position: relative;
            &:after{
                content: '';
                width: 4px;
                height: 4px;
                background-color: #7A7A7A;
                border-radius: 20px;
                position: absolute;
                top: 5px;
                right: -18px;
            }
            &:last-child{
                margin-right: 0;
                &::after{
                    display: none;
                }
            }
        }
        &-socialicon{
            display: flex;
        }
        &-sociallist{
            margin-right: 15px;
            a{
                color: $fontGrey;
                i{
                    font-size: $large_font;
                }
            }
        }
        &-subtitle{
            font-size: $xl_font;
            margin-bottom: 10px;
        }
        &-title{
            font-size: $xl6_font;
            font-weight: $bold;
            margin-bottom: 30px;
        }
        &-section{
            padding: 150px 0 100px;
        }
        &-preview{
            position: relative;
            .back-link-arrow {
                position: absolute;
                left: 21px;
                top: 100px;
                @extend %df;
                @extend %aic;
                height: 20px;
                width: 40px;
                z-index: 1;
                &.white {
                  span.line {
                    background-color: $white;
                  }
                }
                span {
                    position: absolute;
                    top: calc(50% - 1px);
                    left: 0;
                    transition: all .3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
                    &.line {
                        height: 2px;
                        background-color: $black;
                        &.main {
                            width: 100%;
                        }
                        &.hand {
                            width: 15px;
                            transform-origin: 0% 50%;
                            &.top {
                                transform: rotate(-35deg)
                            }
                            &.bottom {
                                transform: rotate(35deg)
                            }
                        }
                    }
                }
                &:hover {
                    span {
                        &.line {
                            &.main {
                                width: calc(100% + 10px);
                            }
                            &.hand {
                                width: 20px;
                                &.top {
                                    transform: rotate(-45deg)
                                }
                                &.bottom {
                                    transform: rotate(45deg)
                                }
                            }
                        }
                    }
                }
            }

        }
        &-para{
            font-size: $base_font;
            margin-bottom: 20px;
            line-height: 1.5;
            color: $black;
        }
        &-tag{
            display: flex;
            flex-wrap: wrap;
            .tag-dtl{
                margin-right: 20px;
                background-color: #E9E9E9;
                padding: 10px 25px;
                border-radius: 20px;
                font-size: $base_font;
                margin-bottom: 20px;
            }
        }
    }
}
@mixin ipadCss {
	.home .section--fix-content {
		width: 40%;
		padding: 30px;
		margin-left: 20px;
		min-width: 40%;
	}
	.section--display-flex {
    padding: 30px;
    padding-right: 0;
  }
	.slick-dots {
    bottom: 85px;
	}
	header .header--wrap {
		&.header--mobile {
			display: flex;
			padding: 10px 20px;
			align-items: center;
			justify-content: space-between;
			.search--bar.active {
				width: 100% !important;
			}
		}
		&.header--desktop {
			display: none;
		}
	}
	.custom-logo-link img {
		margin-top: 10px;
	}
	.graph--img-wrapper {
		flex-direction: column;
		align-items: center;
	}
	.common--section-inner .main--container .insights--report-btn-wrap .btn {
		margin: 0 auto;
		display: block;
	}
}


@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  border: 8px solid rgba($primary_brown, 0.2);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.65,0,.34,1);
  z-index: -1;
}


.seo{
    &--header{
        position: fixed;
        top: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: $boxShadow;
    }
    &--heading{
        font-size: 2.5em;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    &--subheading{

    }
    &--container{
        padding-top: 100px;
        padding-bottom: 100px;
        background-image: url('../../images/seo/banner.webp');
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .banner{
            &--slider{
                .slick-dots{
                    bottom: -73px ;
                    text-align: left !important;

                    li{
                        button{
                            &::before{
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        &-title{
            width: 60%;
        }
        &-form{
            padding: 30px 40px;
            border-radius: 20px;
            width: 30%;

            &-wrap{
                h2{
                    font-size: $base_font;
                    line-height: 1.3;
                }
                &-feild{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;

                    .form-group{
                        input{
                            padding: 10px;
                            border: 1px solid $fontGrey;
                            width: 100%;
                            border-radius: 5px;
                            &::placeholder{
                                color: $black;
                            }
                        }
                        select{
                           
                                padding: 12px 20px 12px 10px;
                                border: 1px solid $fontGrey;
                                border-radius: 5px;
                                margin-right: 10px;

                        }
                        &-checkbox{
                            position: relative;
                            label {
                                position: relative;
                                cursor: pointer;
                                display: flex;
                                align-items: flex-start;
                                line-height: 1.2;
                               
                                &:before {
                                    content: '';
                                    background-color: $white;
                                    border: 1px solid rgba($black, 0.5);
                                    padding: 10px;
                                    display: inline-block;
                                    position: relative;
                                    vertical-align: middle;
                                    cursor: pointer;
                                    margin-right: 10px;
                                    background-color: $primary_green;
                                    // border-radius: 5px;
                                }
                    
                                // a {
                                //     display: inline-block;
                                //     white-space: pre-line;
                                // }
                            }
                            input {
                                display: none;
                                cursor: pointer;
                              
                                &:checked {
                                    + {
                                        label {
                                            &:after {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                top: 1px;
                                                left: 7px;
                                                width: 6px;
                                                height: 14px;
                                                border: solid $black;
                                                border-width: 0 2px 2px 0;
                                                transform: rotate(45deg);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                .submit{
                    width: 100%;
                    justify-content: center;
                }
            }
            .form-group{
                // position: relative;
            }
            .error{
                margin-top: 5px;
                display: block;
                position: absolute;
                // bottom: 0;
                // left: 0;
            }
            .contrycode--wrap{
                display: flex;
                
                &-option{
                    position: relative;

                    &::after{
                        // content: '\203A';
                        content: '';
                        background-image: url('../../images/seo/dropdown.svg');
                        position: absolute;
                        right: 10px;
                        top: 6px;
                        font-size: 2em;
                        pointer-events: none;
                        // transform: rotate(90deg);
                        width: 30px;
                        height: 30px;
                    }

                    #countryCode{
                        cursor: pointer;
                    }
                }
            }
          
            
        }
    }
}

.agency{
    &--container{
        padding-top: 70px;
        padding-bottom: 70px;

        .subhead{
            line-height: 1.5;
        }
        &-achivements{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65%;
            margin: 50px auto 0;
            .achivements-card{
                background-color: $cardGrey;
                padding: 20px;
                border-radius: 15px;
                margin-right: 20px;
                flex: 1;
                &:last-child{
                    margin-right: 0;
                }

                h3{
                    font-size: $xl5_font;
                }
                span{
                    display: block;
                    white-space: nowrap;
                }
            }
        }
        &-strategic{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .strategic-card{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                width: calc(100%/3 - 30px);
                padding: 35px;
                margin-right: 30px;
                position: relative;
                
                &:last-child{
                    margin-right: 0px;
                }

               
                .detail-wrap{
                    width: 80%;
                    min-height: 170px;
                 
                }
                span{
                    display: block;
                    line-height: 1.3;
                    
                }
                .client-comment{
                    text-decoration: underline;
                    cursor: pointer;
                }

                .client{
                    &-thought{
                        border-radius: 20px;
                        padding: 35px;
                        background-color: $primary_green;
                        position: absolute;
                        top: 0;
                        opacity: 0;
                        transition: 0.2s ease-in-out;
                        pointer-events: none;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        &.active{
                            opacity: 1;
                            pointer-events: all;
                        }

                        p{
                            line-height: 1.5;
                        }

                        .close{
                            position: absolute;
                            right: 30px;
                            top: 30px;
                            cursor: pointer;
                        }

                        // &::before{
                        //     content: '';
                        //     background-image: url('../../images/seo/close.svg');
                        //     background-repeat: no-repeat;
                        //     position: absolute;
                        //     top: 35px;
                        //     right: 30px;
                        //     width: 20px;
                        //     height: 20px;
                        //     cursor: pointer;

                        // }
                        
                    }
                    &-detail{
                        display: flex;
                        align-items: flex-start;
                    }
                }

            }
        }
    }
}

.clients{
    &--container{
        background-color: rgba($lightGrey, 0.1);
        padding-top: 70px;
        padding-bottom: 70px;
        &-card{
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            
            &-list{
                // padding: 30px;
                display: flex !important;
                align-items: center;
                flex-direction: column;
                margin-right: 20px;
                margin-bottom: 20px;
            }

        }
    } 
    &--img{
        background-color: $white;
        border-radius: 20px;
        padding: 20px 50px;
        min-height: 125px;
        display: flex;
        align-items: center;
    }  
}

.services{
    &--container{
        padding-top: 80px;
        padding-bottom: 80px;
        &-wrap{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            &-sticky{
                background-color: $primary_green;
                padding: 30px 30px 0px;
               
                border-radius: 20px;
                width: 35%;
                position: sticky;
                top: 100px;
    
                .services-audit{
                    margin-bottom: 90px;
                    h3{
                        line-height: 1.3;
                    }
                    .free-audit{
                        padding: 15px 30px;
                        border-radius: 50px;
                        background-color: $white;
                        display: inline-block;
                    }
                }
            }
            &-details{
                width: 60%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 40px;

                li{
                    width: calc(100%/2 - 25px);
                    margin-bottom: 50px;

                    figure{
                        padding: 10px;
                        background-color: $primary_green;
                        border-radius: 50%;
                        width: fit-content;
                    }
                }
            }
        }
    }
}

.piller{
    &--container{
        // padding-top: 80px;
        padding-bottom: 90px;
        &-cards{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            &-detail{
                border: 1px solid $lightGrey;
                border-radius: 20px;
                padding: 30px;
                width: calc(100%/3 - 30px);
                min-height: 485px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
            }
        }
    }
}

.brands{
    &--container{
        // padding-top: 80px;
        padding-bottom: 80px;
        &-cards{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            
            &-detail{
                border: 1px solid $lightGrey;
                border-radius: 20px;
                padding: 30px;
                width: calc(100%/5 - 20px);
                min-height: 400px;

                figure{
                    padding: 10px;
                    background-color: $primary_green;
                    border-radius: 50%;
                    width: fit-content;

                    img{
                        width: 38px;
                        height: 38px;
                    }
                }

                h4{
                    font-size: $large_font;
                }
            }
        }
    }
}

.engines{
    &--container{
        padding-bottom: 80px;
        display: flex;
        justify-content: space-between;
        
        &-comments{
            background-color: rgba($lightGrey, 0.1);
            padding: 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;
            margin-right: 20px;
            &-detail{
                p{
                    line-height: 1.5;
                }
            }
            &-author{
                span{
                    display: block;
                    font-size: $base_font;
                    font-weight: $normal;
                    line-height: 1.5;
                }
            }
        }
        &-details{
            background-color: $primary_green;
            padding: 30px;
            border-radius: 20px;
            width: 60%;
            position: relative;
            .list-items{
                list-style: disc;
                margin-top: 20px;
                margin-left: 20px;
                li{
                    margin-bottom: 10px;
                    line-height: 1.3;
                }
            }
            &::after{
                content: '';
                background-image: url('../../images/seo/wave.webp');
                background-repeat: no-repeat;
                position: absolute;
                bottom: 0;
                right: 0;
                height: 300px;
                width: 300px;
                background-size: cover;
                // top: 0;
                // right: 0;
                // height: 100%;
                // background-position: top;
            }
        }
    }
}

.contact{
    &--container{
        padding-bottom: 80px;
        &-wrap{
            padding: 50px;
            border-radius: 20px;
            background-color: $primary_green;
            position: relative;

            h2{
                font-size: 3em;
                font-weight: $semiBold;
                font-family: $fontFamily;
                line-height: 1.3;
            }
            .contact-us{
                background-color: $white;
                padding: 15px 40px;
                color: $black;
                font-weight: $semiBold;
                font-size: $large_font;
                border-radius: 40px;
                margin: 30px auto 0;
                width: fit-content;
                display: flex;

            }
            &::after{
                content: '';
                background-image: url('../../images/seo/wave.webp');
                background-repeat: no-repeat;
                position: absolute;
                bottom: 0;
                right: 0;
                height: 300px;
                width: 300px;
                background-size: cover;

                
            }
            &::before{
                content: '';
                background-image: url('../../images/seo/wave.webp');
                background-repeat: no-repeat;
                position: absolute;
                bottom: -5px;
                left: 26px;
                height: 315px;
                width: 305px;
                background-size: cover;
                transform: rotate(90deg);

                @media screen and (max-width:1440px) {
                    height: 100%;
                    bottom: 0;
                    width: 288px;

                }
            }
        }
    }
}

.seo-footer{
    background-color: rgba($lightGrey, 0.1);
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;

    .terms-wrap{
        a{
            display: inline-block;
            color: $black;
            
            
            
        }
    }
    p, a{
        font-size: $base_font;
        font-family: $fontFamily;
        font-weight: $normal;
    }

    
}

.thankyou{
    &-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 85vh;
        
       
        

        img{
            width: 100px;
            // width: 60px;
        }
    }
}

.head{
    font-size: 3em;
    text-align: center;
    font-weight: $semiBold;
    font-family: $fontFamily;
    line-height: 1.3;
}

.border{
    &--radius{
        border-radius: 50%;
    }
}
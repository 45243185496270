[modal-open] {
  &.disable {
    pointer-events: none;
  }
}
.transition-modal {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $primary_green;
  width: 0;
  height: 30px;
  overflow: hidden;
  overflow-y: scroll;
  pointer-events: none;
  z-index: 100;
  overscroll-behavior: contain;
  transform: scale(1);
  will-change: transform;
  &::-webkit-scrollbar {
    display: none;
  }
  .modal-data {
    opacity: 0;
    transition: all .3s ease-in-out;
    will-change: opacity;
    padding: 50px;
    &.show {
      opacity: 1;
    }
    .modal-close-wrap {
      height: 50px;
      @extend %df; @extend %aic;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      button {
        @extend %df; @extend %jcc; @extend %aic;
        width: 50px;
        height: 50px;
        font-size: 30px;
        &::before {
          transform: scaleX(-1);
        }
      }
    }
    p{
      font:$normal $medium_font $fontFamily;
      color:$white;
      margin: 20px 0;
    }
  }
}

.vb {
	>.vb-dragger {
		z-index: 5;
		width: 2px;
		right: 0;
		>.vb-dragger-styler {
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-webkit-transform: rotate3d(0,0,0,0);
			transform: rotate3d(0,0,0,0);
			-webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
			transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
			background-color: rgba(204, 219, 44,1);
			margin: 5px 5px 5px 0;
			border-radius: 20px;
			height: calc(100% - 10px);
			display: block;
		}
		&:hover {
			>.vb-dragger-styler {
				background-color: rgba(204, 219, 44,1);
				margin: 0px;
				height: 100%;
			}
		}
	}
}
.vb.vb-scrolling-phantom {
	>.vb-dragger {
		>.vb-dragger-styler {
			background-color: rgba(204, 219, 44,1);
		}
	}
}
.vb.vb-dragging {
	>.vb-dragger {
		>.vb-dragger-styler {
			background-color: rgba(48, 121, 244,1);
			margin: 0px;
			height: 100%;
		}
	}
}
.vb.vb-dragging-phantom {
	>.vb-dragger {
		>.vb-dragger-styler {
			background-color:rgba(204, 219, 44,1);
		}
	}
}

.page--careers {
    .banner--text {
        margin-left: -15%;
    }
}

.culture {
    $this: &;
    &--section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &--slider {
        margin: 50px auto 20px;
        #{$this}--slide {
            @extend %df;
            @extend %fdc;
            @extend %aic;
            background: transparent;
            .slide {
                &--img {
                    @extend %df;
                    @extend %jcc;
                    @extend %aic;
                    img {
                        filter: grayscale(10);
                        transition: all 0.5s ease-in-out;
                        width: 91%;
                    }
                    margin-bottom: 20px;
                }
                &--title {
                    font-size: $large_font;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    text-align: center;
                    line-height: 1.3;
                }
                &--desc {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                    line-height: normal;
                    text-align: center;
                }
            }
            &.swiper-slide-active {
                .slide--img {
                    img {
                        filter: none;
                    }
                }
                .slide--desc {
                    opacity: 1;
                }
            }
        }
        .circle-container {
            @extend %df;
            @extend %jcc;
            @extend %aic;
            position: absolute;
            left: 0;
            top: 0;
            padding: 0 20px;
            width: 100%;
            .pivot-circle-inner {
                margin: 0 1.3%;
                &.grey {}
            }
        }
        .mask-container {
            z-index: 3;
            position: relative;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            box-shadow: 0px -10px 0 30px $white;
            &::after {
                content: '';
                width: calc(100% / 5);
                display: block;
                height: 100px;
                position: absolute;
                left: 0;
                top: 100%;
                transform: translateX(-50%);
                background: $white;
            }
            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }
    &--navigation {
        @extend %df;
        @extend %jcc;
        @extend %aic;
        #{$this}--prev {
            margin-right: 30px;
        }
        #{$this}--prev,
        #{$this}--next {
            @extend %df;
            @extend %jcc;
            @extend %aic;
            background-color: $primary_green;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            transition: all .3s ease-in-out;
            cursor: pointer;
            font-size: 25px;
            &.swiper-button-disabled {
                cursor: not-allowed;
                filter: grayscale(10);
            }
        }
    }
}

.masonry--slider {
    overflow: hidden;
    overflow-x: scroll;
    // scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    cursor: none;
    /* Firefox 64 */
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    .grid {
        height: calc(100vh - (100vh / 3) + 1px);
        width: auto;
        background-color: #bfbfbf;
    }
    /* clear fix */
    .grid:after {
        content: '';
        display: block;
        clear: both;
    }
    /* ---- .grid-item ---- */
    .grid-item {
        float: left;
        height: calc(100vh / 3);
        img {
            height: 100%;
            vertical-align: middle;
        }
    }
}

.accordion--container {
    list-style: none;
    counter-reset: list-Counter;
    &>li {
        counter-increment: list-Counter;
        background-color: #F8F8F8;
        padding: 25px 0;
        margin-bottom: 10px;
        &:last-child {
            // margin-bottom: 0;
        }
        &>div:nth-child(1) {
            &::before {
                content: counter(list-Counter, decimal-leading-zero);
                position: absolute;
                top: 0;
                left: 0;
                font-weight: $bold;
                font-size: $large_font;
                font-family: $fontFamily;
            }
        }
    }
    .accordion-header {
        @extend %df;
        @extend %aic;
        @extend %jcsb;
        padding-left: 70px;
        position: relative;
        div {
            padding-left: 60px;
            border-left: 1px solid #707070;
        }
        a {
            min-width: 50px;
            height: 50px;
            background-color: $primary_green;
            border-radius: 50%;
            @extend %df;
            @extend %aic;
            @extend %jcc;
            font-size: 1.2em;
            color: $black;
        }
    }
    .accordion-heading,
    .accordion-description {
        font-size: $medium_font;
    }
}

.career {
    &--title {
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        h1 {
            font-weight: $bold;
            font-size: $medium_font;
            color: $primary_brown;
            margin-left: 50px;
        }
        &-link-arrow {
            color: $primary_brown;
            transform: rotate(45deg);
            transition: transform 0.2s ease-out;
            font-size: 30px;
            &:hover {
                transform: rotate(180deg);
            }
        }
    }
}

.hidden-data {
    padding-top: 100px;
    padding-bottom: 50px;
    h4 {
        font-weight: $bold;
    }
    h4,
    ul {
        margin-bottom: 15px;
    }
    p {
        color: $fontGrey;
        font-size: $medium_font;
        margin-bottom: 15px;
        &+h4,
        &+ul {
            margin-top: 25px;
        }
    }
    ul {
        padding-left: 30px;
        &+h4,
        &+p {
            margin-top: 25px;
        }
        li {
            margin-bottom: 15px;
            line-height: 1.2;
            list-style-type: disc;
            color: $fontGrey;
            font-size: $medium_font;
        }
    }
    .btn {
      color: $black;
    }
}

.transition--circle {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: background .3s ease;
    overflow: hidden;
    width: 200vw;
    height: 200vw;
    z-index: 10000;
    background-color: $white;
    @media only screen and (max-width: 768px) {
      width: 300vw;
      height: 300vw;
    }
    .loader {
        display: none;
    }
    &.other {
        @extend %df;
        @extend %aic;
        @extend %jcc;
        background-color: $primary_green;
        &>.loader {
            position: relative;
            background: $white;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
            @extend %df;
            @extend %aic;
            @extend %jcc;
            border-radius: 50%;
            min-width: 100px;
            min-height: 100px;
            background-color: $primary_brown;
            &::after {
                @include rings(3s, 0s);
            }
            &::before {
                @include rings(3s, 0.5s);
            }
        }
        .pivot-circle-inner {
            display: none;
        }
    }
    .pivot-circle-inner {
        width: 0;
        height: 0;
        overflow: hidden;
        border-radius: 50%;
        transition: all .5s ease;
    }
    &.home {
        @extend %df;
        @extend %aic;
        @extend %jcc;
        .pivot-circle-inner {
            width: 50vh;
            height: 50vh;
            opacity: 1;
            position: relative;
            box-shadow: none;
            display: block;
            @media only screen and (max-width: 768px) {
                width: 40vh;
                height: 40vh;
            }
            &.shadow {
                box-shadow: 0 0 0 30px $primary_green;
            }
            .text-block {
                @extend %df;
                @extend %aic;
                @extend %jcc;
                flex-direction: column;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                // background-color: $primary_green;
                min-width: 50vh;
                min-height: 50vh;
                text-align: center;
                font-size: $xxl_font;
                font-weight: $semiBold;
                line-height: 1.5;
                color: $black;
                span {
                    display: block;
                    animation: loaderMain 16s infinite ease-out both;
                    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
                    opacity: 0;
                    margin-bottom: 40px;
                    &.black {
                        color: $black;
                    }
                }
                .loader--text {
                    position: absolute;
                }
                .text-one {
                    span {
                        &:first-child {
                            animation-delay: 0s;
                        }
                        &:nth-child(2) {
                            animation-delay: 0.5s;
                        }
                    }
                }
                .text-two {
                    span {
                        &:first-child {
                            animation-delay: 4s;
                        }
                        &:nth-child(2) {
                            animation-delay: 4.5s;
                        }
                    }
                }
                .text-three {
                    span {
                        &:first-child {
                            animation-delay: 8s;
                        }
                        &:nth-child(2) {
                            animation-delay: 8.5s;
                        }
                    }
                }
                .text-four {
                    span {
                        &:first-child {
                            animation-delay: 12s;
                        }
                        &:nth-child(2) {
                            animation-delay: 12.5s;
                        }
                    }
                }
            }
        }
        .loader {
            display: none;
        }
    }
}

@keyframes loaderMain {
    0%,
    24% {
        opacity: 0;
    }
    4%,
    20% {
        opacity: 1;
    }
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

.full-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
}

.transition--footer {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: $primary_green;
    transform: translateY(100%);
    top: 0;
    left: 0;
}

.contact {
    &--form {
        padding-bottom: 100px;
        .thankyou--message{
            font: $bold $xxl_font $fontFamily;

        }
        .nl {
            &-form {
                width: 100%;
                margin: 0.3em auto 0 auto;
                font: $bold 2.8125em $fontFamily;
                line-height: 2;
                color: $black;
                position: relative;
                &.disable{
                    opacity:0.1;
                }
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                input:not([type="checkbox"]){
                    &::-webkit-input-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                    &:active::-webkit-input-placeholder,
                    &:focus::-webkit-input-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                    &::-moz-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                    &:active::-moz-placeholder,
                    &:focus::-moz-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                    &:-ms-input-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                    &:active::-ms-input-placeholder,
                    &:focus::-ms-input-placeholder {
                        color: $black;
                        font-size: 1em;
                    }
                }
                .error {
                    color: $secondary_red;
                    font-size: 30%;
                }
                .check--radio {
                    position: relative;

                    &-wrap {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;

                        input {
                            cursor: pointer;
                        }

                        label {
                            cursor: pointer;
                            margin-left: 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
            &-field {
                display: inline-block;
                position: relative;
                .error {
                  position: absolute;
                  left: 0;
                  bottom: -13px;
                  white-space: nowrap;
                  min-width: 100%;
                }
                &-toggle {
                    line-height: 1.4;
                    display: inline-block;
                    color: $greyTint;
                    cursor: pointer;
                    border-bottom: 1px solid $black;
                    padding: 0 5px;
                }
                ul {
                    position: absolute;
                    visibility: hidden;
                    background: $white;
                    left: 0;
                    top: 50%;
                    font-size: 60%;
                    opacity: 0;
                    transform: translateY(-40%) scale(0.9);
                    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
                    li {
                        color: $black;
                        position: relative;
                    }
                }
                &-go {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.1);
                    width: 1.8em;
                    text-align: center;
                    color: transparent;
                    &:before {
                        content: "\e903";
                        font-size: 75%;
                        color: $black;
                        width: 100%;
                        line-height: 2.5;
                        display: block;
                    }
                }
                &-open {
                    z-index: 10000;
                    ul {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                        transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
                        &.dropdown {
                            position: fixed;
                            transform: translate(-50%, -50%) scale(1);
                            left: 50%;
                            z-index: 1;
                        }
                    }
                    &~.nl-overlay {
                        opacity: 1;
                        visibility: visible;
                        transition-delay: 0s;
                    }
                }
            }
            &-submit {
                position: relative;
                color: $white;
                font-size: 50%;
                &-wrap {
                    margin-top: 0.4em;
                }
            }
            &-dd {
                ul {
                    padding: 0.5em;
                }
                li {
                    padding: 0 1.5em 0 0.5em;
                    cursor: pointer;
                    white-space: nowrap;
                }
                &-checked {
                    color: #478982;
                    &:before {
                        content: "\e911";
                        position: absolute;
                        right: 1em;
                        font-size: 60%;
                        // line-height: 3;
                    }
                }
            }
            &-ti {
                &-text {
                    ul {
                        min-width: 15em;
                        li {
                            input {
                                width: 100%;
                                padding: 0.2em 2em 0.2em 0.5em;
                                border-bottom: none;
                                color: $black;
                            }
                        }
                    }
                }
            }
            &-overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $white;
                opacity: 0;
                z-index: 9999;
                visibility: hidden;
                -webkit-transition: visibility 0s 0.3s, opacity 0.3s;
                -moz-transition: visibility 0s 0.3s, opacity 0.3s;
                transition: visibility 0s 0.3s, opacity 0.3s;
            }
        }
        .no-touch {
            .nl {
                &-dd {
                    li {
                        &:hover {
                            background: rgba(0, 0, 0, 0.05);
                        }
                        &:active {
                            color: #478982;
                        }
                    }
                }
                &-form {
                    .nl-submit {
                        &:hover,
                        &:active {
                            background: #69B1A9;
                        }
                        &:hover {
                            &:before {
                                background: #58a199;
                            }
                        }
                    }
                }
            }
        }
    }
}

//   .nl-ti-text ul li.nl-ti-example {
// 	font-size: 40%;
// 	font-style: italic;
// 	font-weight: 400;
// 	padding: 0.4em 1em;
// 	color: rgba(0, 0, 0, 0.2);
// 	border-top: 1px dashed rgba(255, 255, 255, 0.7);
// 	em {
// 	  color: $white;
// 	}
//   }
@media screen and (max-width: 45em) {
    .nl-form {
        font-size: 3em;
    }
}

@media screen and (max-width: 25em) {
    .nl-form {
        font-size: 2em;
    }
}

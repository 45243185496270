%df {
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
}
%aic {
	align-items: center;
}
%aifs {
	align-items: flex-start;
}
%aife {
	align-items: flex-end;
}
%jcc {
	justify-content: center;
}
%jcsb {
	justify-content: space-between;
}
%fdc {
	flex-direction: column;
}
%fw {
	flex-wrap: wrap;
}
%jcfe {
	justify-content: flex-end;
}

@import "iconVariables";
@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wnc09b');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wnc09b#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wnc09b') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wnc09b') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wnc09b##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="pr--"],
[class*=" pr--"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.pr--link1 {
    &:before {
      content: $pr--link1; 
    }
  }
.pr--marketing-automation {
    &:before {
        content: $pr--marketing-automation;
    }
}

.pr--web-app-analytics {
    &:before {
        content: $pr--web-app-analytics;
    }
}

.pr--right-arrow {
    &:before {
        content: $pr--right-arrow;
    }
}

.pr--down-arrow {
    &:before {
        content: $pr--down-arrow;
    }
}

.pr--digital-transformation {
    &:before {
        content: $pr--digital-transformation;
    }
}

.pr--reseach {
    &:before {
        content: $pr--reseach;
    }
}

.pr--audience {
    &:before {
        content: $pr--audience;
    }
}

.pr--communication {
    &:before {
        content: $pr--communication;
    }
}

.pr--brand-integration {
    &:before {
        content: $pr--brand-integration;
    }
}

.pr--market-strategy {
    &:before {
        content: $pr--market-strategy;
    }
}

.pr--app-marketing {
    &:before {
        content: $pr--app-marketing;
    }
}

.pr--affiliate-marketing {
    &:before {
        content: $pr--affiliate-marketing;
    }
}

.pr--seo {
    &:before {
        content: $pr--seo;
    }
}

.pr--biddable {
    &:before {
        content: $pr--biddable;
    }
}

.pr--single-view-dashboard {
    &:before {
        content: $pr--single-view-dashboard;
    }
}

.pr--crm {
    &:before {
        content: $pr--crm;
    }
}

.pr--tick {
    &:before {
        content: $pr--tick;
    }
}

.pr--meaurement-protocol {
    &:before {
        content: $pr--meaurement-protocol;
    }
}

.pr--attribution-modeling {
    &:before {
        content: $pr--attribution-modeling;
    }
}

.pr--experiential-design {
    &:before {
        content: $pr--experiential-design;
    }
}

.pr--user-testing {
    &:before {
        content: $pr--user-testing;
    }
}

.pr--experience-design {
    &:before {
        content: $pr--experience-design;
    }
}

.pr--design-dev {
    &:before {
        content: $pr--design-dev;
    }
}

.pr--visual-design {
    &:before {
        content: $pr--visual-design;
    }
}

.pr--influencer-marketing {
    &:before {
        content: $pr--influencer-marketing;
    }
}

.pr--branded-content {
    &:before {
        content: $pr--branded-content;
    }
}

.pr--social-community {
    &:before {
        content: $pr--social-community;
    }
}

.pr--meme-marketing {
    &:before {
        content: $pr--meme-marketing;
    }
}

.pr--next {
    &:before {
        content: $pr--next;
    }
}

.pr--facebook {
    &:before {
        content: $pr--facebook;
    }
}

.pr--twitter {
    &:before {
        content: $pr--twitter;
    }
}

.pr--instagram {
    &:before {
        content: $pr--instagram;
    }
}

.pr--linkedin {
    &:before {
        content: $pr--linkedin;
    }
}

.pr--plus {
    &:before {
        content: $pr--plus;
    }
}

.pr--minus {
    &:before {
        content: $pr--minus;
    }
}

.pr--previous {
    &:before {
        content: $pr--previous;
    }
}
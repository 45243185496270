.our--work {
    height: 100vh;
}

.work {
    &--categories {
        margin-bottom: 50px;
        position: relative;
        z-index: 2;
    }
    &--toolbar {
        ul {
            @extend %df;
            @extend %jcc;
            li {
                font-weight: $normal;
                font-size: $base_font;
                transition: all 0.3s ease-out;
                padding: 0 20px;
                cursor: pointer;
                a {
                    color: $black;
                }
                &.selected {
                    font-weight: $bold;
                }
            }
        }
    }
    &--list {
        @extend %df;
        flex-wrap: wrap;
        position: relative;
        margin-top: 30px;
        &:after {
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #f0f0f0;
            z-index: 2;
            width: 1px;
        }
    }
    &--item {
        width: calc(80% / 3);
        overflow: hidden;
        position: relative;
        padding-bottom: 13%;
        margin: 0 auto;
        // &:before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   order-right: 1px solid #ebebeb;
        //   order-top: 1px solid #ebebeb;
        //   order-left: 1px solid #ebebeb;
        //   pointer-events: none;
        // }

        &.custom {
            .work--item {
                &-type {
                    @media only screen and (max-width: 1440px) {
                        max-width: 50px;
                    }
                }
                &-header {

                    @media only screen and (max-width: 1440px) {
                        top: 10px;
                    }
                    
                    top: 32px;
                }
            }
        }
        
        &:hover {
            .work--item-circle {
                //  transform: translate(-50%, -50%) scale(2);
                // &::before{
                //   background-color: attr(data-bgcolor);
                //
                // }
                img {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
        &-container {
            padding: 9% 9% 0;
            position: relative;
        }
        &-inner {
            width: 100%;
            padding-top: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            position: relative;
        }
        // &-link {
        // 	position: absolute;
        // 	z-index: 99;
        // 	top: 0;
        // 	left: 0;
        // 	display: block;
        // 	cursor: pointer;
        // 	width: 100%;
        // 	height: 100%;
        // 	text-decoration: none;
        // 	background-color: transparent;
        // 	box-shadow: none;
        // 	padding: 0;
        // 	border: none;
        // 	appearance: none;
        // 	&:active~.work--item-padding {
        // 		img {
        // 			transform: translateX(-50%) scale(.95);
        // 		}
        // 	}
        // }
        &-grad {
            pointer-events: none;
            background: radial-gradient(center, hsla(0, 0%, 100%, .19), hsla(0, 0%, 100%, 0));
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            transform-origin: center;
            border-radius: 50%;
            transition: transform .45s ease;
        }
        &-circle {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            transform-origin: center;
            border-radius: 50%;
            transition: transform .45s ease;
            display: none;
            display: block;
            background-color: #EDF1F2;
            // &::before{
            //   content:'';
            //   position: absolute;
            //   top: 50%;
            //   left: 50%;
            //   width: 100%;
            //   height: 100%;
            //   transform: translate(-50%, -50%);
            //   transform-origin: center;
            //   border-radius: 50%;
            //   transition: transform .45s ease;
            //   display: none;
            //   display: block;
            //   background-color: $greyTint;
            // }
        }
        &-header {
            width: 100%;
            top: 13.5%;
            padding: 0 4%;
            text-align: center;
            max-height: 55px;
            flex-direction: column;
            z-index: 6;
            position: absolute;
            left: 0;
            @extend %df;
            @extend %jcc;
            @extend %aic;
        }
        &-type {
            font: $light $xl5_font $fontFamily;
            margin-bottom: 5px;
            display: block;
            color: $black;
            transition: transform .5s ease;
            flex: 0 0 auto;
            max-width: 143px;
        }
        &-image {
            position: absolute;
            max-width: 100%;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            bottom: 7%;
            z-index: 5;
            will-change: transform;
            opacity: 0;
            transition: transform 1.5s ease-out;
            transform-origin: center;
            overflow: hidden;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            text-align: center;
            img {
                // width: 100%;
                // object-fit: contain;
                vertical-align: middle;
                @media only screen and (max-width: 1440px) {
                    zoom: 0.58;
                }
            }
            &.work--item-show {
                transition: opacity .3s ease, transform .3s ease; 
            }
        }
        &-outside {
            // transform: translateY(80px);
            text-align: center;
            position: absolute;
            left: 0; 
            right: 0;
            top: 130%;
            padding: 0 9%;
            &-brandname {
                font: $bold $xxl_font $fontFamily;
                color: #585556;
            }
            &-para {
                font: $normal $base_font $fontFamily;
                color: #585556;
                margin-top: 10px;
                position: relative;
                &.two {
                    display: none;
                }
                &::after {
                    content: 'View';
                    font-family: 'Montserrat';
                    text-decoration: underline;
                    display: block;
                    font-weight: $semiBold;
                    margin-top: 10px;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                    font-size: 14px;
                }
            }
        }
        &-loaded {
            .work--item-image {
                opacity: 1;
                transition: opacity 1s ease;
            }
        }
        &:hover .work--item-outside-para::after {
            opacity: 1;
        }
    }
}

.workItem--empty {
    .workItem__header {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        max-height: 100%;
    }
}

.shiny-metals-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
    h3 {
        font-size: $xxl_font;
        margin-bottom: 70px;
        font-weight: $bold;
        text-align: center;
    }
    .total-metals {
        position: absolute;
        left: 50%;
        top: calc(50% - 35px);
        transform: translate(-50%, -30%);
        font-size: 25em;
        font-weight: $bold;
        color: $primary_green;
        margin-left: 9%;
        transition: all 1000ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 750ms;
        opacity: 0;
    }
    .metals-list {
        position: relative;
        &.show {
            ul {
                li {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
            .total-metals {
                transform: translate(-50%, -50%);
                opacity: 1;
            }
            .and-growing {
                opacity: 1;
            }
        }
    }
    ul {
        font-size: $xxl_font;
        max-width: 768px;
        margin: 0 auto;
        @extend %df;
        flex-wrap: wrap;
        position: relative;
        li {
            width: calc(100% / 2);
            margin-bottom: 60px;
            position: relative;
            z-index: 1;
            transform: translateX(-50px);
            opacity: 0;
            transition: all 600ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
            &:nth-child(2n) {
                text-align: right;
                transform: translateX(50px);
            }
        }
    }
    .and-growing {
        font-weight: $bold;
        font-size: $xxl_font;
        color: $primary_green;
        text-align: center;
        opacity: 0;
        transition: all 600ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
    }
}

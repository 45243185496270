.menu--desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    @extend %df;
    @extend %aic;
    @extend %jcc;
    transform: translate(-100%, 0);
    &.open {}
    // .imagechange {
    //   mix-blend-mode: multiply;
    //   position: absolute;
    //   bottom: -10vh;
    //   left: -12vw;
    //   width: 800px;
    //   height: 800px;
    //   pointer-events: none;
    //   background-image: url(../images/girl-sprite.png);
    //   background-size: cover;
    //   background-position: left top;
    //   transform: translate(0, 100%);
    // }
    &_inner {
        @extend %df;
        @extend %aic;
        @extend %jcc;
        padding-top: 100px;
        height: 100vh;
        width: 100%;
        background-color: $primary_green;
        overflow: hidden;
        // overflow-y: scroll;
        -ms-overflow-style: none;
        /* IE 11 */
        scrollbar-width: none;
        /* Firefox 64 */
        &::-webkit-scrollbar {
            width: 0px;
        }
        &>ul {
            z-index: 1;
            border-right: 1px solid #707070;
            padding-right: 5%;
            margin-right: 5%;
            padding-bottom: 30px;
            min-width: 500px;
            li {
                margin-bottom: 5%;
                overflow: hidden;
                a {
                    display: inline-block;
                }
                &:hover {
                    .menu {
                        &--title {
                            color: #0A0101;
                            &::after {
                                letter-spacing: normal;
                                padding-left: 0;
                            }
                            &::before {
                                letter-spacing: normal;
                                padding-left: 0;
                                opacity: 1;
                                transform: translate(0, 0);
                            }
                        }
                        &--subtitle {
                            color: #0A0101;
                        }
                    }
                    span {
                        display: block;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .menu {
                    &--title {
                        display: block;
                        color: #585556;
                        font: $blackBold $xl5_font $fontFamily;
                        display: block;
                        padding-top: 30px;
                        position: relative;
                        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
                        &:before,
                        &:after {
                            position: absolute;
                            left: 0;
                            transform: translate(100%, 0);
                            width: 100%;
                            // height: 100%;
                            display: block;
                            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
                            letter-spacing: 13px;
                        }
                        &::before {
                            content: attr(title-name);
                            padding-left: 30px;
                            font: $bold 0.55em $fontFamily;
                            top: 30px;
                            white-space: nowrap;
                            left: calc(100% + 6px);
                            opacity: 0;
                        }
                        // &::after {
                        //   content: attr(transition-name);
                        //   padding-left: 30px;
                        //   bottom: 0;
                        //   padding: 20px 0 0;
                        // }
                    }
                    &--subtitle {
                        font: $semiBold $xxl_font $fontFamily;
                        color: #818081;
                        transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
                        //transition-delay: 80ms;
                        @extend %df;
                        @extend %aic;
                        a {
                            display: -webkit-inline-flex;
                            display: -ms-inline-flex;
                            display: inline-flex;
                            color: #818081;
                            z-index: 1;
                            position: relative;
                            &:hover {
                                transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
                                color: $black;
                                &::after {
                                    color: #818081;
                                }
                            }
                            &::after {
                                content: '|';
                                display: block;
                                margin: 0 10px;
                            }
                            &:last-child {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            &:hover {
                &~.pivot-outlined {
                    #Path_19115,
                    #Path_19116,
                    #Path_19117 {
                        stroke-dasharray: 2000;
                    }
                    #Path_19118 {
                        stroke-dasharray: 9160;
                    }
                }
                // & ~ .imagechange {
                //   animation: play 0.5s steps(5) infinite;
                // }
            }
        }
    }
    .info-container {
        @extend %df;
        @extend %fdc;
        padding-top: 30px;
        .info-block {
            margin-bottom: 10%;
            h3 {
                font-weight: $semiBold;
                margin-bottom: 15px;
                font-size: $xxl_font;
            }
            address {
                font-size: $base_font;
                line-height: 1.3;
            }
        }
    }
    .pivot-outlined-placeholder {
        width: 70vh;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }
    .pivot-outlined {
        width: 70vh;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
        z-index: 1;
        #ref-Path_19115,
        #ref-Path_19116,
        #ref-Path_19117,
        #ref-Path_19118 {
            fill: none;
            stroke: white;
            stroke-width: 2px;
        }
        #Path_19115,
        #Path_19116,
        #Path_19117,
        #Path_19118 {
            fill: none;
            stroke: white;
            stroke-width: 5px;
            transition: all 1.5s ease-in-out;
        }
        #Path_19115,
        #Path_19116,
        #Path_19117 {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
        }
        #Path_19118 {
            stroke-dasharray: 4580;
            stroke-dashoffset: 4580;
        }
    }
    .morph-shape {
        width: 200px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        fill: $primary_green;
    }
}

.cursor {
    opacity: 1;
    // &__inner {
    //     //mix-blend-mode: difference;
    //     position: absolute;
    //     will-change: transform;
    //     z-index: 999999;
    //     border-radius: 25px;
    //     pointer-events: none;
    //     &--circle {
    //         width: 25px;
    //         height: 25px;
    //         // border: 1px solid white;
    //         background-color: $primary_green;
    //         // transition: transform .1s, width .5s ease-in-out, height .5s ease-in-out;
    //         &.hover {
    //             // mix-blend-mode: normal;
    //             //border: 1px solid $white;
    //             opacity: 0;
    //             box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    //             &+.cursor__inner--dot {
    //                 opacity: 0;
    //             }
    //         }
    //     }
    //     &--dot {
    //         width: 5px;
    //         height: 5px;
    //         background-color: white;
    //         border-radius: 50%;
    //     }
    // }
    .link-arrow {
        color: $black;
        font-size: 30px;
        transform: none !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 999999;
        position: absolute;
        pointer-events: none;
        &::before {
            display: block;
            transform: rotate(-135deg);
            opacity: 0;
            transition: all .3s ease;
            mix-blend-mode: difference;
            -webkit-text-fill-color: $black;
            /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $white;
        }
        &.show {
            &::before {
                opacity: 1;
            }
        }
        &.scroller {
            &::before {
                transform: translate(80%, -11px);
                opacity: 1;
            }
            &.left {
                &::before {
                    transform: translate(calc(-80% - 26px), -11px) rotate(-180deg);
                    opacity: 1;
                }
            }
        }
    }
}

// * {
// cursor: none!important;
// }
// @keyframes play {
//    100% { background-position: -4000px; }
// }
.social {
    ul {
        @extend %df;
        @extend %aic;
        li {
            margin-right: 20px;
            a {
                @extend %df;
                @extend %aic;
                @extend %jcc;
                font-size: $xl5_font;
                color: #080202;
                &:hover {
                    color: rgba(0, 0, 0, 0.8);
                }
            }
        }
    }
}
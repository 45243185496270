.page--about_us {
    .pivot-circle {
        position: absolute;
    }
    .banner--text {
        margin-left: 0; 
    }
    .info--blocks-section {
        background-color: #D5D5D5;
        &.show {
            .info--block {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                h3,
                p,
                a {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                    // transform: translate3d(0, 0, 0) scale(1.15);
                }
            }
        }
    }
    
    .info--blocks {
        @extend %df;
        .info--block {
            opacity: 0;
            transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
            padding: 4% 6%;
            width: calc(100% / 3);
            svg,
            h3,
            p,
            a {
                transform: translate3d(0, 80px, 0);
                transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                opacity: 0;
            }
            svg {
                margin-bottom: 50px;
                width: 60%;
                transform: none!important;
            }
            h3 {
                font-size: $xl5_font;
                margin-bottom: 40px;
                font-weight: $light;
            }
            p {
                margin-bottom: 40px;
                font-size: $medium_font;
                line-height: 1.8;
            }
            a {
                color: $black;
                text-decoration: underline;
                font-size: $xl_font;
                display: block;
            }
            // &:nth-child(1) {
            // 	.pivot {
            // 		transform: scale(1.15) translate3d(-30%, 0, 0);
            // 	}
            // }
            // &:nth-child(3) {
            // 	.pivot {
            // 		transform: scale(1.15) translate3d(30%, 0, 0);
            // 	}
            // }
        }
    }
}

// .pivot-container {
// padding: 0 2%;
.pivot {
    // min-width: calc(100vw / 3);
    width: 100%;
    border-radius: 800px;
    background-color: $white;
    padding-bottom: 40%;
}

// }
.bossman {
    // &--img-container {
    // 	position: relative;
    // 	svg {
    // 		position: absolute;
    // 		right: 0;
    // 		top: 5%;
    // 		right: 14%;
    //     width: 80%;
    // 		#line01, #line02, #line03 {
    // 			stroke-dasharray: 1000;
    // 			stroke-dashoffset: 1000;
    // 			// stroke-dasharray: 2000;
    // 			animation: makeLine 5s alternate infinite;
    // 		}
    // 		#line04 {
    // 			stroke-dashoffset: 4580;
    // 			stroke-dasharray: 4580;
    // 			animation: makeLine1 5s linear infinite;
    // 		}
    // 	}
    // 	img {
    // 		position: relative;
    // 		z-index: 1;
    // 	}
    // 	.message {
    // 		@extend %df; @extend %aic; @extend %jcc; @extend %fdc;
    // 		margin-top: 20px;
    // 		line-height: 23px;
    // 		font-size: $medium_font;
    // 		font-weight: $normal;
    // 		text-transform: uppercase;
    // 		span {
    // 			font-weight: $bold;
    // 			display: block;
    // 		}
    // 	}
    // }
    &--quote {
        h2 {
            overflow: hidden;
            padding-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            width: 90%;
            margin: auto;
            @media only screen and (max-width: 768px) {
                line-height: 1.5;
                font-size: 2.1em;
            }
            span {
                opacity: 1;
                color: #FBFBFB;
                transform: translateY(150px) rotate(5deg);
                transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                line-height: 1.3;
                padding-right: 20px;
            }
        }
        blockquote {
            opacity: 0;
            transform: translate(0, 50px);
            transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
            @media only screen and (max-width: 768px) {
                opacity: 1;
                transform: none;
                margin-top: 20px;
            }
            p {
                font-size: $xl5_font;
                font-weight: $light;
                @media only screen and (max-width: 768px) {
                    font-size: $xxl_font;
                    line-height: 1.5;
                }
            }
        }
        &.show {
            h2 {
                span {
                    opacity: 1;
                    transform: translateY(0) rotate(0deg);
                    color: $black;
                }
            }
            blockquote {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}

@keyframes makeLine {
    from {
        stroke-dasharray: 1000;
    }
    50% {
        stroke-dasharray: 2000;
    }
    to {
        stroke-dasharray: 1000;
    }
}

@keyframes makeLine1 {
    from {
        stroke-dasharray: 4580;
    }
    50% {
        stroke-dasharray: 9160;
    }
    to {
        stroke-dasharray: 4580;
    }
}

.our--clients-container {
    @extend %df;
    @extend %jcc;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;
    .mask {
        $this: &;
        &--container {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
    .mask {
        min-width: 25vw;
        min-height: 25vw;
        border-radius: 50%;
        position: absolute;
        display: block;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 0;
        // background-size: 100vw 100vh;
    }
    .mask-left {
        top: 0;
        left: 0;
        transform: translateX(-80px);
        background-position-x: -60vw;
        background-image: url('../../images/about-left-mask.webp');
    }
    .mask-right {
        bottom: 0;
        right: 0;
        transform: translateX(120px);
        background-image: url('../../images/about-right-mask.webp');
        background-position-x: 10vw;
    }
    .clients-wrap {
        width: 50%;
        padding: 5%;
        h3 {
            font-size: $xl5_font;
            font-weight: $light;
            text-align: center;
        }
        .client-logos {
            @extend %df;
            flex-wrap: wrap;
            @extend %aic;
            .logo-block {
                width: calc(100% / 5);
                @extend %df;
                @extend %aic;
                @extend %jcc;
                img {
                    opacity: 0;
                    transform: translate3d(0, 20px, 0);
                    transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
                    transition-delay: attr(tr-delay);
                    width: 100%;
                }
            }
        }
        &.show {
            .logo-block {
                img {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
    // .info-wrap {
    // 	@extend %df; @extend %aic;
    // 	width: 40%;
    // 	box-shadow: inset 0 100px $primary_green;
    // 	padding: 5%;
    // 	padding-top: calc(5% + 100px);
    // 	font-weight: $light;
    // 	font-size: $xl5_font;
    // 	line-height: normal;
    // 	background-color: $secondary_grey;
    // }
}

.meeting--room-wrap {
    .parallax {
        background-repeat: no-repeat;
        background-attachment: fixed;
        height: 400px;
    }
    img {
        width: 100%;
        visibility: hidden;
        vertical-align: middle;
    }
    .circle-wrap {
        $circle: 25vw;
        width: $circle;
        max-width: $circle;
        height: $circle;
        max-height: $circle;
        background-color: $primary_green;
        box-shadow: 40px 40px 80px rgba(0, 0, 0, 0.5);
        border-radius: $circle;
        text-align: center;
        @extend %df;
        @extend %aic;
        @extend %fdc;
        @extend %jcc;
        padding: 50px;
        margin-left: auto;
        margin-right: 10%;
        margin-top: -13%;
        position: relative;
        opacity: 0.5;
        // transform: translate3d(0, 100px, 0) scale(0.4);
        transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
        &.show {
            // transform: translate3d(0, 0, 0) scale(1);
            opacity: 1;
        }
        p {
            margin-bottom: 40px;
            font-size: $medium_font;
            line-height: 1.5;
        }
        a {
            font-size: $base_font;
            font-weight: $bold;
            color: $black;
            text-decoration: underline;
        }
    }
}

// .other--pages-wrap {
// 	@extend %df;
// 	.hover-link-block {
// 		width: calc(100% / 3);
// 	}
// }
// .hover-link-block {
// 	overflow: hidden;
// 	position: relative;
// 	padding: 5%;
// 	@extend %df; @extend %fdc; @extend %jcsb;
// 	min-height: 590px;
// 	&:hover {
// 		img {
// 			transform: translate(-50%, -50%) scale(1.1);
// 		}
// 	}
// 	.shadow, img {
// 		position: absolute;
// 		left: 50%;
// 		top: 50%;
// 		transform: translate(-50%, -50%);
// 	}
// 	img {
// 		z-index: 1;
// 		object-fit: cover;
// 		height: 100%;
// 		width: 100%;
// 		transition: transform 5s cubic-bezier(0.215, 0.61, 0.355, 1);
// 	}
// 	.shadow {
// 		width: 100%;
// 		height: 100%;
// 		z-index: 2;
// 		background-color: rgba(0, 0, 0, 0.5);
// 	}
// 	h3, a {
// 		z-index: 3;
// 		color: $white;
// 	}
// 	h3 {
// 		font-size: $xl5_font;
// 		font-weight: $bold;
// 	}
// 	a {
// 		text-decoration: underline;
// 		font-size: $xl_font;
// 		font-weight: $medium;
// 	}
// }
.marquee--section {
    padding: 50px 0;
    position: relative;
    overflow: hidden;
    position: relative!important;
    top: 0;
    left: 0;
    // pointer-events: none; //enable leter
    @extend %df;
    @extend %aic;
    // overflow: auto;
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
    &::-webkit-scrollbar {
        width: 0px;
    }
    &.scrollright {
        transform: scale(-1);
    }
    .slidesholder {
        @extend %df;
        @extend %aic;
        // position: absolute!important;
        // top: 0;
        // left: 0;
    }
    .horizontal--scroller {
        white-space: nowrap;
        min-height: 100%;
        // min-width: 100%;
        // overflow: hidden;
        @extend %df;
        p {
            white-space: nowrap;
            font-size: 8.500em;
            font-weight: $bold;
            padding: 0 90px;
            display: flex;
            flex-direction: column;
            width: auto;
            color: $black;
            align-items: flex-start;
            backface-visibility: hidden;
        }
        // &:nth-child(odd) {
        // 	color: $primary_brown;
        // }
        // &:nth-child(even) {
        // 	color: $black;
        // }
        span {
            font-size: 0.5em;
            font-weight: $light;
            display: inline-block;
            white-space: nowrap;
        }
    }
}

// SVG Animations
$animDelay: 20;
$dots: 26;
$triangles: 30;
#Group_12982 {
    transition: all 1s ease;
    transform: translate(-10.79px, -35.5px)!important;
}

.info--block:hover {
    .bulbs {
        #Group_12982 {
            transform: translate(-10.79px, 4.5px)!important;
        }
    }
    .wings {
        #Group_13207>path,
        #Group_13207>circle {
            fill: #fec106;
        }
        #Group_13205 path {
            fill: yellow;
        }
    }
    .space {
        #Line_73,
        #Line_74,
        #Line_75 {
            animation: blaster 650ms linear infinite;
        }
    }
}

#Group_13207>path,
#Group_13207>circle,
#Group_13205>g>path {
    transition: all 800ms ease;
}

@for $i from 1 through $dots {
    @if $i>24 {
        #Group_13207>circle:nth-child(#{$i}) {
            transition-delay: #{$i * $animDelay}ms;
        }
    }
    @else {
        #Group_13207>path:nth-child(#{$i}) {
            transition-delay: #{$i * $animDelay}ms;
        }
    }
}

@for $i from 1 through $triangles {
    #Group_13205>g:nth-child(#{$i})>path {
        transition-delay: #{$i * $animDelay}ms;
    }
}

@keyframes blaster {
    0% {
        stroke-dasharray: 10;
        stroke-dashoffset: -2;
    }
    100% {
        stroke-dasharray: 10;
        stroke-dashoffset: -66;
    }
}

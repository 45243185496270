.common--footer {
    @extend %df;
    @extend %aic;
    @extend %jcc;
    background-color: $primary_green;
    color: $black;
    font-size: $xl5_font;
    font-weight: $extraLight;
    padding: 10px 10%;
    width: 100%;
    height: 200px;
    line-height: 1.3;

    a {
        text-decoration: underline;
        color: $black;
        font-weight: $light;
    }
}

footer {
    background-color: $greyTint;
    padding: 50px 0;

    .footer {
        &--wrapper {

            &-social {
                // @extend %df;
                // align-items: center;
                // padding-top: 50px;
                // justify-content: center;
                // @media only screen and (max-width: 640px) {
                //     flex-direction: column-reverse;
                // }

                h4 {
                    font-size: $medium_font;
                    font-weight: $bold;
                    margin-bottom: 30px;

                    @media only screen and (max-width: 640px) {
                        text-align: center;
                    }
                }

                ul {
                    @extend %df;
                    // margin: 0 30px;

                    &.footer--email {
                        @media only screen and (max-width: 640px) {
                            flex-direction: column;
                            margin-bottom: 20px;

                            .sep {
                                display: none;
                            }
                        }

                        li {
                            font: $bold $base_font $fontFamily;
                            color: $black;
                            margin-right: 10px;
                            @media only screen and (max-width: 640px) {
                                margin-bottom: 10px;
                                margin-right: 0;
                                text-align: center;
                            }

                            a {
                                color: $black;
                            }
                        }
                    }

                    li {
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        a {
                            // padding: 0 5px;

                            i {
                                color: $black;
                                font-size: 24px;
                            }
                        }
                    }
                }
            }

            &-info {
                margin-top: 30px;
                display: flex;
                justify-content: center;
                @media only screen and (max-width: 640px) {
                    flex-direction: column;
                }
            }

            &-inquiry {
                margin-right: 50px;
                padding-right: 50px;
                border-right: 2px dashed $lightGrey;
                @media only screen and (max-width: 640px) {
                    margin-right: 0px;
                    padding-right: 0px;
                    border-right: none;
                    border-bottom: 1px dashed $lightGrey;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }

                h4 {
                    font-size: $medium_font;
                    font-weight: $bold;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 640px) {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                }

                ul {
                    @extend %df;
                    // justify-content: space-evenly;
                    @media only screen and (max-width: 640px) {
                        justify-content: center;
                    }
                    
                    li {
                        margin-right: 30px;
                        text-align: left;
                        &:last-child {
                            margin-right: 0px;
                        }

                        h6 {
                            font-size: $base_font;
                            color: rgba($black, 0.5);
                            margin-bottom: 5px;
                        }

                        .phoneNumber {
                            // font-size: $medium_font;
                            font-size: $small_font;
                            font-weight: $bold;
                            color: $black;
                            display: block;
                            // margin-bottom: 10px;
                            margin-top: 10px;
                        }

                        .mail {
                            text-decoration: underline;
                            font-size: $small_font;
                            color: $black;
                        }
                    }
                }
            }

            &-policy {
                font-size: $small_font;
                text-decoration: underline;
                color: $black;
                font-weight: $bold;
                margin-top: 20px;
                display: block;
                @media only screen and (max-width: 640px) {
                    text-align: center;
                }
            }
        }
        &--social{
            &-links{
                margin: 37px 0px 30px;
            }
        }

        &--tabs {
            width: fit-content;
            margin: 0 auto;
            background: $white;
            padding: 10px;
            border-radius: 50px;
            @media only screen and (max-width: 640px) {
                white-space: nowrap;
            }

            button {
                font-weight: 600;
                @media only screen and (max-width: 640px) {
                    padding: 0px 10px;
                    height: 30px;
                }

                &.green {
                    color: $white;
                }
            }

            &-heading {
                font-size: $medium_font;
                font-weight: $bold;
                margin: 40px 0px 20px;
            }

            &-address {
                display: none;
                font-size: $medium_font;
                text-align: center;
                font-weight: $medium;
                line-height: 1.4;
                margin: 0 auto;

                &.active {
                    display: block;
                }
            }
        }
    }
}
// .footer--wrapper-policy{
//     text-align: center;
//     a{
//         color:$primary_brown;
//         font-size: $medium_font;

//     }
// }
.privacy{
    padding: 40px 0;
    h1{
        font-size: $medium_font;
        font-weight: $bold;
        margin-bottom: 10px;
        color:$primary_brown;
}
    
    h3,h4{
        font-size: $medium_font;
        font-weight: $semiBold;
        margin-bottom: 10px;
        color:$primary_brown;
    }
    
    
    p, li{
        font-weight: $normal;
        color:$primary_brown;
        font-size: $base_font;
        line-height: 1.5;
}
ul{
    margin-left: 20px;
    margin-bottom: 20px;
}
    
p{
    margin-bottom: 20px;
    .mail{
        color: $black;
    }
}
    li{
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 10px;
    }
    a{
        color:$primary_green;
        font-size: $base_font;
        text-decoration: underline;

    }

    &--policy-container {
        padding-top: 70px;
    }

}
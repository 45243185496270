
.service--carrousel{
	&-wrapper{
		position: relative;
		
		&.fixed{
			position: sticky;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
		}
		.carrousel {
		  z-index: 2;
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
		  pointer-events: none;
				&__slide {
				  width: 100vw;
				  height: 100vh;
				  display: -webkit-box;
				  display: -ms-flexbox;
				  display: flex;
				  -webkit-box-align: center;
				  -ms-flex-align: center;
				  align-items: center;
				}
				&__back {
				  z-index: 1;
				  position: absolute;
				  pointer-events: none;
				  top: 0;
				  left: 0;
					&__item {
					  position: absolute;
					  top: 0;
					  left: 0;
					  width: 100vw;
					  height: 100vh;
					  overflow: hidden;
					}
				}
				&__bg {
					&-img{
						position: absolute;
						top: 0;
						left: 0;
						-webkit-transform: translateX(50%);
						transform: translateX(50%);
						width: 100vw;
						&-holder {
						  position: absolute;
						  top: 0;
						  left: 0;
						  width: 100vw;
						  height: 100vh;
						  overflow: hidden;
						  margin: auto;
						}
						img {
						  position: absolute;
						  top: 0;
						  left: 0;
						  -webkit-transform: translateX(-50%);
						  transform: translateX(-50%);
						  height: 100vh;
						  line-height: 0;
							width: 100%;
						}
					}
					&-color {
					  position: absolute;
					  top: 0;
					  left: 0;
					  width: 100vw;
					  height: 100vh;

					}
					&-title {
					  margin-left: 5rem;
					 // opacity: .33;
					  user-select: none;
						h3,p{
							font-family: $fontFamily;
							color:#31312F;

						}
						h3{
							font-size: $xl5_font;
							margin-bottom: 20px;
							font-weight: $bold;
						}
						p{
							font-size: $xxl_font;
							line-height: 1.5;
							font-weight: $normal;
						}
						&-holder {
						  position: absolute;
						  top: 0;
						  left: 0;
						  display: flex;
						  align-items: center;
						  height: 100vh;

						}
						&__line {
							padding-bottom: .1em;
							&-holder {
								overflow: hidden;
							}
						}
					}
				}
			&__bullets {
			  display: flex;
			  pointer-events: none;
				justify-content: space-evenly;
				width: 70%;
				margin: 0 auto;
				padding-bottom: 20px;
				&-holder {
				  z-index: 1;
				  position: absolute;
				  bottom: 7.1vh;
					width: 100%;
				}
				&__bullet {
					width: 2vh;
					height: 2vh;
				  border-radius: 50%;
				  overflow: hidden;
				  pointer-events: all;
				  cursor: pointer;
					opacity: 0.5;
					transition: all 0.5s ease-out;
					&.active{
						width: 5vh;
    				height: 2vh;
						border-radius: 50px;
						opacity: 1;
						background-color: #000!important;
					}
					&-holder {
					  position: relative;
					  width: 100%;
						display: flex;
				    justify-content: center;
				    align-items: center;
						// &:after{
						// 	content: '';
						// 	width: 100%;
						// 	background-color: $black;
						// 	position: absolute;
						// 	height: 1px;
						// 	opacity: 0.5;
						// }
						.pagination-text {
							white-space: nowrap;
							line-height: 24px;
							padding-top: 24px;
							display: flex;
							color: $black;
							transition: all .3s ease-in-out;
							position: absolute;
							text-align: center;
							top: 0;
							justify-content: center;
							align-items: center;
							}
					}
				}
				// &__active {
				//   position: absolute;
				//   top: 0;
				//   left: 0;
				// 	&-bg {
				// 	  position: absolute;
				// 	  top: 0;
				// 	  left: 0;
				// 	  width: 6.5vh;
				// 	  height: 6.5vh;
				// 	  border-radius: 50%;
				// 	  background: white;
				// 	  -webkit-transform: translate(-50%, -50%);
				// 	  transform: translate(-50%, -50%);
				// 	}
				// 	&-bullet {
				// 	  position: absolute;
				// 	  top: 0;
				// 	  left: 0;
				// 	  width: 5vh;
				// 	  height: 5vh;
				// 	  border-radius: 50%;
				// 	  background: grey;
				// 	  -webkit-transform: translate(-50%, -50%);
				// 	  transform: translate(-50%, -50%);
				// 	}
				// }
			}
			&__mask {
			  position: absolute;
			  top: 0;
			  left: 0;
				width: calc(60vh*600/600);
			  height: 60vh;
			  border-radius: 50%;
			  overflow: hidden;
			  -webkit-mask-image: -webkit-radial-gradient(white, black);
				&-holder {
				  z-index: 2;
				  pointer-events: none;
				  position: absolute;
				  top: 0;
				  left: 0;
				}
				&-shadow {
				  position: absolute;
				  top: 0;
				  left: 0;
				  width: calc(110vh*644/919);
				  height: 110vh;
				  margin-left: -16vh;
				  margin-top: -13vh;
				  background: url("./images/service/big-drop-shadow.webp");
				  background-repeat: no-repeat;
				  background-size: cover;
				}
				&__back {
				  position: absolute;
				  top: 0;
				  left: 0;
					width: calc(60vh*600/600);
					height: 60vh;
				  overflow: hidden;
				}
				&__content {
				  z-index: 3;
				  position: absolute;
				  width: 46.6vh;
				  height: 100%;
				}
			}
			&__contents {
			  z-index: 3;
			  position: relative;
			}
			&__content {
			margin: auto;
				&__edito{
					max-width: 19vw;
		      font-size: 1.2vw;
		      line-height: 1.5em;
		      margin-left: 37vh;
					&-holder{
						position: absolute;
						top: 50%;
						left: 50%;
						display: flex;
						max-width: 30vw;
						flex-wrap: wrap;
						justify-content: center;
						transform: translate(0, -50%);
						margin-left: 37vh;
					}
				}

				&-holder {
				  z-index: 5;
				  position: absolute;
				  top: 0;
				  left: 0;
				  width: 100vw;
				  height: 100vh;
				  display: flex;
				  align-items: center;
				  visibility: hidden;
				  opacity: 0;
				  pointer-events: none;
				}


			}
			&--card{
				background-color: $white;
				padding: 20px;
				display: flex;
		    justify-content: center;
		    flex-direction: column;
		    align-items: center;
				text-align: center;
				border-radius: 20px;
				margin-right: 10px;
				margin-bottom: 20px;
				width: auto;
				&-icon{
					display: block;
					font-size: 50px;
					margin-bottom: 20px;
				}
				&-title{
					font:$bold $base_font $fontFamily;
					color:#31312F;
				}
			}
		}

	}
}
